(function($) {

    const INTERVAL_SEC = 3;

    $.fn.commentForm = function(options) {
        options = $.extend({
            itemsContainerSelector: null,
            replyButtonSelector: null,
            mentionButtonSelector: null,
            inlineReplyContainer: null,
            controlAnonymous: null,
            commentRefreshButtonSelector: null,
            ignoreTargetSaveUrl: null,
            ignoreTargetData: null,
            style: 'default',
            displayGuestTrackInfoNow: true,
            selfAccountInfo: null,
        }, options);
        const $form = $(this);

        if ($form.data('comment-form-initialized') === true) {
            // error 2回初期化するのはナシで!
            return;
        }
        $form.data('comment-form-initialized', true);

        const $initialFormContainer = $form.parent();
        const itemsContainerSelector = options.itemsContainerSelector;
        const $itemsContainer = $(options.itemsContainerSelector);
        const replyButtonSelector = options.replyButtonSelector;
        const mentionButtonSelector = options.mentionButtonSelector;
        const inlineReplyContainer = options.inlineReplyContainer;
        const controlAnonymous = options.controlAnonymous;
        const commentRefreshButtonSelector = options.commentRefreshButtonSelector;

        const $contentTextInput = $form.find('[name="content"]');
        const $submitButton = $form.find('.submit');
        const $submitOptionBlock = $form.find('.submit-options');

        // 匿名チェックボックス
        if (controlAnonymous) {
            const $nickname = $form.find('[name="nickname"]');
            $form.find('[name="anonymous"]').on('change', function() {
                const checked = $(this).prop("checked");
                const userThumbnail = $form.find('.form-user-thumbnail');
                const anonymousThumbnail = $form.find('.form-anonymous-thumbnail');
                if (checked) {
                    userThumbnail.hide();
                    anonymousThumbnail.show();
                    if (typeof $nickname.data('modified') === 'undefined') {
                        $nickname.data('modified', "");
                    }
                    $nickname.prop("disabled", false).val($nickname.data('modified'));
                } else {
                    anonymousThumbnail.hide();
                    userThumbnail.show();
                    $nickname.data("modified", $nickname.val());
                    $nickname.prop("disabled", true).val($nickname.data("username"));
                }
            });
            if ($nickname.data('username')) {
                $nickname.prop("disabled", true).val($nickname.data("username"));
            }
        }

        // キー入力
        $contentTextInput.on("change keyup", updateSubmitButtonStatus).trigger('change');

        // リフレッシュ
        let latestCommentNumber = 0;
        let $refreshButton = null;
        if (commentRefreshButtonSelector) {
            $refreshButton = $(commentRefreshButtonSelector);
            $refreshButton.on('click', function() {
                $refreshButton.find('.glyphicon,.fa').addClass('fa-spin');
                $refreshButton.addClass('disabled');
                latestCommentNumber = detectMaxCommentNumber();

                $itemsContainer.one("pjax:end", function() {
                    $refreshButton.removeClass('disabled');
                    $refreshButton.find('.glyphicon,.fa').removeClass('fa-spin');
                    commentItemsWhichNumberIsGreaterThan(
                        latestCommentNumber
                    ).addClass('last-posted-comment-effect');
                });

                $.pjax.reload(itemsContainerSelector, { timeout:5000 });
            });
        }

        //noinspection JSUnusedLocalSymbols
        function wrapByCommentFormAttachEffect($form, fn) {
            //$form.removeClass('comment-form-attached-effect');
            fn();
            //$form.wrap('<div></div>');
            //$form.parent().css({
            //    position: 'relative',
            //    height: $form.height() + 13 // 謎の13px誤差
            //    //overflow: 'hidden'
            //});
            //$form.css({
            //    position: 'absolute',
            //    top: 0,
            //    left: 0,
            //    width: $form.parent().width()
            //});
            //$form.addClass('comment-form-attached-effect');
            //setTimeout(function() {
            //    $form.css({
            //        position: '',
            //        top: "",
            //        left: "",
            //        width: ""
            //    });
            //    $form.removeClass('comment-form-attached-effect');
            //    $form.unwrap();
            //}, 500);
        }

        // リプライ
        if (replyButtonSelector) {
            $itemsContainer.on('click', replyButtonSelector, function() {
                const $button = $(this);
                const $commentElement = $button.closest('.comment');
                const $box = $commentElement.find(inlineReplyContainer).not(
                    $commentElement.find('.list-view ' + inlineReplyContainer)
                );
                $form.find('[name=reply_to]').val($button.data('to'));
                if ($button.is('button')) $button.hide(); // ラジオボタンは隠さない

                wrapByCommentFormAttachEffect($form, function() {
                    $box.append($form);
                    $itemsContainer.find(replyButtonSelector).not($button).show();
                    $form.find('.reply-target-number .number').text($button.data('to'));
                    $form.find('.reply-target-number').show();
                });
                if ($refreshButton) {
                    $refreshButton.trigger('commentForm:changeState');
                }
            });
        }

        if (mentionButtonSelector) {
            $itemsContainer.on('click', mentionButtonSelector, function() {
                const $button = $(this);
                const $commentElement = $button.closest('.comment');
                const $box = $commentElement.find(inlineReplyContainer).not(
                    $commentElement.find('.list-view ' + inlineReplyContainer)
                );

                const textarea = $form.find('[name=content]');
                let content = textarea.val();
                if (content !== "") {
                    content += "\n\n";
                }
                textarea.val(content + ">> " + $button.data('to'));

                wrapByCommentFormAttachEffect($form, function() {
                    $box.append($form);
                    $itemsContainer.find(replyButtonSelector).not($button).show();
                    $form.find('.reply-target-number .number').text($button.data('to'));
                    $form.find('.reply-target-number').show();
                });
                $refreshButton.trigger('commentForm:changeState');
            });
        }

        $form.find('.cancel').on('click', function() {
            $form.find('[name=reply_to]').val($initialFormContainer.data('reply-to'));
            $itemsContainer.find(replyButtonSelector).show()
                .filter(':radio').prop('checked',false); // ラジオボタンはチェックを外す

            wrapByCommentFormAttachEffect($form, function() {
                $initialFormContainer.append($form);
                $form.find('.reply-target-number .number').text('');
                $form.find('.reply-target-number').hide();
            });
            $refreshButton.trigger('commentForm:changeState');
        });

        // Pjaxコメント送信 おちつくまで数秒待つ
        $form.data('comment-form-submitting', false);
        $form.data('comment-form-waiting', false);
        $form.find('form').on('submit', function (event) {
            if (!canSubmit()) {
                return false;
            }
            if ($form.data('comment-form-submitting') !== true && $form.data('comment-form-waiting') !== true) {
                $form.data('comment-form-submitting', true);
                $form.data('comment-form-waiting', true);
                updateSubmitButtonStatus();
                const options = {
                    push: false,
                    replace: true,
                    timeout: 0,
                    scrollTo: null
                };
                // ツリーで返信先番号があればページング中のURLを維持する
                if ($form.find('[name=reply_to]').val()) {
                    options.url = location.href;
                }

                if (commentRefreshButtonSelector && $(commentRefreshButtonSelector).is('.should-inject-new-arrival')) {
                    // タイムラインモードの自動更新が発動中ならこっち

                    const $targetForm = $(event.currentTarget);
                    const formData = $targetForm.serializeArray();

                    $.ajax({
                        url: $targetForm.attr('action'),
                        type: $targetForm.attr('method'),
                        data: formData,
                        dataType: 'json'
                    }).done(function(data){
                        if (!!data && !!data.status) {
                            if (data.status === 'ok' || data.status === 'warning') {
                                if (data.status === 'warning') {
                                    bootbox.alert(data.warning);
                                }
                                // リロード指示メッセージを発行
                                $(commentRefreshButtonSelector).trigger('commentForm:post');

                                // 初期化
                                if ($form.find('.reply-target-number').is(':visible')) {
                                    $initialFormContainer.append($form);
                                }
                                $contentTextInput.val("").trigger("keyup").trigger("change");
                                $contentTextInput.focus();

                                updateInitialFormContainerVisibility();
                                $form.find('.reply-target-number .number').text('');
                                $form.find('.reply-target-number').hide();
                                $form.find('[name=reply_to]').val($initialFormContainer.data('reply-to'));

                            } else if (data.status === 'error') {
                                bootbox.alert(data.error);
                            }

                            $form.data('comment-form-submitting', false);
                            updateSubmitButtonStatus();
                        }
                    }).fail(function(){
                        // 安全のため pjax で再試行
                        event.result = true;
                        $.pjax.submit(event, itemsContainerSelector, options);
                    });
                    event.preventDefault();
                } else {
                    event.result = true;
                    $.pjax.submit(event, itemsContainerSelector, options);
                }
                setTimeout(function() {
                    $form.data('comment-form-waiting', false);
                    updateSubmitButtonStatus();
                }, INTERVAL_SEC * 1000);
            }
            return false;
        });

        if (!!window.hide_comments) {
            window.hide_comments.start(
                $itemsContainer, options.style, options.displayGuestTrackInfoNow, options.ignoreTargetSaveUrl,
                options.ignoreTargetData, options.selfAccountInfo
            );
        }

        // Pjaxイベントすべて
        $itemsContainer.on("pjax:start", function() {
            //$form.find('input,textarea,button,.btn').addClass('disabled');

            if (!!window.lazyload_fix) {
                window.lazyload_fix.prepare($itemsContainer); // in lazy-load-fix.js
            }

        }).on("pjax:beforeReplace", function(event, content, options) {
            window.hide_comments.prepare($itemsContainer);
            if ($form.find('.reply-target-number').is(':visible')) {
                $initialFormContainer.append($form);
                // $form.hide().slideDown();   //?? うごかない
            } else {
                if (options.type === 'POST' && $form.data('comment-form-submitting')) {
                    $contentTextInput.focus();
                }
            }
        }).on("pjax:success", function(/*event, xhr, textStatus, errorThrown, options*/) {
            const errorCount = $itemsContainer.find('.comment-errors').data('errors');
            if ($form.data('comment-form-submitting') && (
                isNaN(errorCount) || parseInt(errorCount) === 0
                )) {
                $contentTextInput.val("").trigger("keyup").trigger("change");
            }

            // スライダーUIの描画更新を早めるため以下やや遅れて実行
            setTimeout(function(){
                if (!!window.lazyload_fix) {
                    window.lazyload_fix.done($itemsContainer); // in lazy-load-fix.js
                }
                if (!!window.hide_comments) {
                    window.hide_comments.update($itemsContainer);
                }
            }, 10);

        }).on("pjax:error", function(event, xhr, textStatus, errorThrown) {
            if (xhr.status === 0 && errorThrown === 'abort') {
                return false;
            }
            if (xhr.status === 0 && errorThrown === 'timeout') {
                bootbox.alert("サーバーとの通信に時間がかかりすぎたため、処理を中断しました。ページを再読み込みしてください。");
                // POSTはクライアント側タイムアウトがないはず
                return false;
            }
            if (xhr.status === 0 && xhr.statusText === 'error') {
                bootbox.alert("サーバーとの通信が行えませんでした。ページを再読み込みしてください。");
                return false;
            }
            bootbox.alert("予期しないネットワークエラーが発生しました。ページを再読み込みしてください");
            return false;
        }).on("pjax:end", function() {
            updateInitialFormContainerVisibility();
            $form.find('.reply-target-number .number').text('');
            $form.find('.reply-target-number').hide();
            //$form.find('input,textarea,button,.btn').removeClass('disabled');
            $form.find('[name=reply_to]').val($initialFormContainer.data('reply-to'));

            $form.data('comment-form-submitting', false);
            updateSubmitButtonStatus();
            if (commentRefreshButtonSelector) {
                $(commentRefreshButtonSelector).removeClass('disabled');
                $(commentRefreshButtonSelector).find('.glyphicon,.fa').removeClass('fa-spin');
            }
        });

        function updateInitialFormContainerVisibility() {
            const $firstPaginationButton = $itemsContainer.find('.pagination .first');
            if ($firstPaginationButton.length === 0 || $firstPaginationButton.hasClass('disabled')) {
                $initialFormContainer.show();
            } else {
                $initialFormContainer.hide();
            }
        }

        function updateSubmitButtonStatus() {
            if (!canSubmit()) {
                $submitButton.addClass("disabled");
            } else {
                $submitButton.removeClass("disabled");
            }
        }

        function canSubmit() {
            const empty = $.trim($contentTextInput.val()).length === 0;
            const submitting = $form.data('comment-form-submitting') || $form.data('comment-form-waiting');
            return !(empty || submitting);
        }

        function appendEnterToSend() {
            const isMac = navigator.userAgent.match(/Mac|PPC/);
            const isIOs = navigator.userAgent.match(/iOS/);
            $(
                '<label for="enter-to-send"><input type="checkbox" id="enter-to-send"/> <small class="text-muted">' + ((isMac || isIOs) ? '&crarr;' : 'Enter') + 'を押して送信</small></label>'
            ).appendTo($submitOptionBlock).tooltip({
                placement: 'top',
                html: true,
                title: '入力中に ' + (isMac ? '&#8984; + &crarr;' : 'Ctrl + Enter') + ' でも送信できます'
            });
            $contentTextInput.on('keydown', function(event) {
                const modKey = isMac ? event.metaKey : event.ctrlKey;
                if (($form.find('#enter-to-send').prop('checked') || modKey) && event.keyCode === 13) {
                    $form.find('form').trigger('submit');
                    return false;
                }
            });
            // 基本はENTER効かない
            $form.find('form input').on('keypress', function(event) {
                if (event.keyCode === 13 && !(event.metaKey || event.ctrlKey)) {
                    event.preventDefault();
                }
            });
        }
        appendEnterToSend();

        function detectMaxCommentNumber() {
            let v = 0;
            $itemsContainer.find('[data-key]').each(function() {
                v = Math.max(v, parseInt($(this).data('key')));
            });
            return v;
        }

        function commentItemsWhichNumberIsGreaterThan(n) {
            return $itemsContainer.find('[data-key]').filter(function() {
                return parseInt($(this).data('key')) > n;
            });
        }

        updateInitialFormContainerVisibility();
        updateSubmitButtonStatus();
    };
})(jQuery);
