(function($) {

    const report = function (callback) {
        const snippetVars = window['goog_snippet_vars'];
        if (typeof (snippetVars) === 'function') {
            snippetVars();
        } else {
            console.log('No goog_snippet_vars() because dev env?');
            callback();
            return;
        }
        window.google_conversion_format = "3";
        const handler = window['google_trackConversion'];
        if (typeof (handler) === 'function') {
            handler({
                onload_callback: callback
            });
        }
    };

    $.fn.trackSubmitAsAdWordsConversion = function() {
        // options = $.extend({
        // }, options);
        this.each(function() {
            const self = $(this);
            $(this).on('submit', function() {
                if (self.data('tracked')) {
                    return true;
                } else {
                    const confirmed = self.data('confirmed'); // 確認ダイアログが yes 済みかまたは存在しないときのみ
                    if (confirmed === true || 'undefined' === typeof(confirmed)) {
                        self.data('tracked', true);
                        report(function() {
                            self.trigger('submit');
                        });
                    }
                }
                return false;
            });
        });
    };
})(jQuery);
