export default {
    'brands-0': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-0" */'./fa-index-free-brands-0'),
    'brands-1': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-1" */'./fa-index-free-brands-1'),
    'brands-2': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-2" */'./fa-index-free-brands-2'),
    'brands-3': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-3" */'./fa-index-free-brands-3'),
    'brands-4': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-4" */'./fa-index-free-brands-4'),
    'brands-5': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-5" */'./fa-index-free-brands-5'),
    'brands-6': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-6" */'./fa-index-free-brands-6'),
    'brands-7': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-7" */'./fa-index-free-brands-7'),
    'brands-8': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-8" */'./fa-index-free-brands-8'),
    'brands-9': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-9" */'./fa-index-free-brands-9'),
    'brands-b': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-b" */'./fa-index-free-brands-b'),
    'brands-f': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-f" */'./fa-index-free-brands-f'),
    'brands-e': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-e" */'./fa-index-free-brands-e'),
    'brands-c': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-c" */'./fa-index-free-brands-c'),
    'brands-d': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-d" */'./fa-index-free-brands-d'),
    'brands-a': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-brands-a" */'./fa-index-free-brands-a'),
}