(function($) {
    $.fn.adjustNavScrollX = function() {
        const $wrapper = $(this);
        if ($wrapper && !!$wrapper.scrollLeft) {
            const x = $wrapper.find(".nav").width() - $wrapper.width();
            if (x > 0) {
                const active = $wrapper.find(".active");
                const w = active.position().left / $wrapper.width();
                $wrapper.scrollLeft(x * w);
            }
        }
    };
})(jQuery);
