(function($) {

    $.fn.contentOperations = function(options) {
        //noinspection JSUnusedAssignment
        options = $.extend({
        }, options);

        this.each(function() {
            const $container = $(this);

            const $form = $container.find('form');

            // ソース表示
            $container.find('#show-content-source').on('change', function(){
                if ($(this).prop('checked')) {
                    $container.find('.body').hide();

                    const sourceContainer = $form.find('>.source-field');
                    sourceContainer.find('textarea').attr('readonly', true).css({
                        backgroundColor: '#fff',
                        cursor: 'text'
                    });
                    sourceContainer.find('input').attr('disabled', true).css({
                        backgroundColor: '#fff',
                        cursor: 'text'
                    });

                    $form.find('>*').not(sourceContainer).hide();
                    $container.find('.source').show();
                } else {
                    $container.find('.source').hide();
                    $container.find('.body').show();
                }
            });

            // 編集
            $container.find('#modify-content').on('click', function() {
                $container.children('.content').trigger('modify-content-on');
                $container.find('.body').each(function(){
                    const self = $(this);
                    if (self.closest('.markdown-toolbox-dialog-preview').length === 0) {
                        self.hide();
                    }
                });
                $container.find('.source').show();
                $(this).addClass('disabled');
            }).css({
                outline: 'none'
            });

            $container.find('#content-modification-cancel').on('click', function() {
                $container.children('.content').trigger('modify-content-off');
                $container.find('.body').show();
                $container.find('.source').hide();
                $container.find('#modify-content').removeClass('disabled');
            }).css({
                outline: 'none'
            });
        });
    };
})(jQuery);
