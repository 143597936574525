(function ($) {
    $.fn.extend({"commentActionMenu": function (options) {
        const settings = $.extend({
            commentSelector: '.comment',
            commentContentSelector: '.content',
            actionMenuSelector: '.comment-action-menu',
            buttonSelector: '.comment-action-button',
            commentActionModalSelector: '.comment-action-modal',
            tooltipSelector: '.tooltip-for-menu',
        }, options);

        const CLASS_NAME = {
            ACTIVE: 'active',
            BUTTON_OPEN: 'open',
        }

        const EVENT_NAMESPACE = 'commentMenu';

        const root = this;

        function registerHandler() {
            const comments = root.find(settings.commentSelector);
            // 画面のどこかタッチ（クリックだけど、マウスに関してはhoverのon/offで表示/非表示が切り替わるので意味はない）で
            // タッチされたコメント以外のメニューを消す
            const inactivate = function (e) {
                const target = $(e.target);
                if (target.parents(settings.actionMenuSelector).length > 0) {
                    return;
                }

                if (target.parents(settings.commentActionModalSelector).length > 0) {
                    return;
                }

                const currentComment = target.parents(settings.commentSelector);

                const menu = $(settings.commentSelector)
                    .not(currentComment)
                    .children(settings.commentContentSelector)
                    .children(settings.actionMenuSelector);
                menu.removeClass(CLASS_NAME.ACTIVE);
                menu.find(settings.tooltipSelector).hide();
            };
            $(document).off('.' + EVENT_NAMESPACE).on('click.' + EVENT_NAMESPACE, inactivate);

            $(document).on('action-menu-button-click', function (e, param) {
                const menu = $(param.origin).parents(settings.actionMenuSelector);
                $(settings.actionMenuSelector).not(menu).removeClass(CLASS_NAME.ACTIVE);
            });

            // cssの :hover を使わない理由:
            // 絵文字パレットを開いているときにhoverが外れてパレットごと消えるなんて事態を避けるため、ある程度柔軟に制御しておきたい
            // jQuery マウスイベントだけでカバーできないタッチデバイス用のメニュー表示も
            // 非表示にする制御は↑の document 全体のクリックで何とかしている
            comments.children(settings.commentContentSelector)
                .off('mouseenter.' + EVENT_NAMESPACE)
                .on('mouseenter.' + EVENT_NAMESPACE, function (e) {
                    const menu = $(this).find(settings.actionMenuSelector);
                    const isInsideMenu = $(e.target).parents(settings.actionMenuSelector).length > 0;
                    const isLink = $(e.target).is('a, button, img');

                    if (isLink) {
                        return true;
                    }

                    if (menu.hasClass(CLASS_NAME.ACTIVE) && !isInsideMenu) {
                        hideMenu.call(this);
                    } else {
                        // 表示
                        menu.addClass(CLASS_NAME.ACTIVE);
                    }

                    // 非表示
                    comments.children(settings.commentContentSelector).not(this).each(hideMenu);
                });

            comments.children(settings.commentContentSelector)
                .off('mouseleave.' + EVENT_NAMESPACE).on("mouseleave." + EVENT_NAMESPACE, hideMenu);

            // コメント修正フォームが開かれたらメニューのアクションを実行できないようにする
            comments.children(settings.commentContentSelector).off('modify-content-on').on('modify-content-on', function () {
                const menu = $(this).find(settings.actionMenuSelector);
                menu.removeClass(CLASS_NAME.ACTIVE);
                menu.find(settings.tooltipSelector).hide();

                menu.find('button').attr('disabled', 'disabled');
            });

            comments.children(settings.commentContentSelector).off('modify-content-off').on('modify-content-off', function () {
                const menu = $(this).find(settings.actionMenuSelector);

                menu.find('button').removeAttr('disabled');
            });

            function hideMenu() {
                const menu = $(this).find(settings.actionMenuSelector);
                // 絵文字パレットが開いてるときはメニューも表示しっぱなしにする
                if (menu.find(settings.buttonSelector).hasClass(CLASS_NAME.BUTTON_OPEN)) {
                    return;
                }
                menu.removeClass(CLASS_NAME.ACTIVE);
                menu.find(settings.tooltipSelector).hide();
            }
        }

        registerHandler();
        this.on('pjax:success', function () {
            registerHandler();
        });
    }});
})(jQuery);
