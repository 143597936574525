import {IconDefinition, library} from '@fortawesome/fontawesome-svg-core'
import md5 from "md5"
import faIndexFreeBrands from './fa-index-free-brands'
import faIndexFreeRegular from './fa-index-free-regular'
import faIndexFreeSolid from './fa-index-free-solid'

export async function faUseIcons(htmlClasses: string[]) {
    const requests = htmlClasses.map(analyzeHtmlClass).flat()
    const result = await Promise.all(requests.map(loadDefinition))
    library.add(...result.filter((x): x is IconDefinition => x !== null))
}

type SupportedSeries = 'classic' | 'sharp'
type SupportedType = 'solid' | 'regular' | 'brands' | 'thin' | 'light' | 'duotone'

interface IconRequest {
    series: SupportedSeries,
    iconType: SupportedType,
    iconName: string,
}

const prefix2series: Record<string, SupportedSeries> = {
    'fa-sharp': 'sharp',
}

const prefix2type: Record<string, SupportedType> = {
    'fas': 'solid',
    'far': 'regular',
    'fab': 'brands',
    'fal': 'light',
    'fad': 'duotone',
    'fa-solid': 'solid',
    'fa-regular': 'regular',
    'fa-brands': 'brands',
    'fa-thin': 'thin',
    'fa-light': 'light',
    'fa-duotone': 'duotone',
}

// https://fontawesome.com/docs/web/style/style-cheatsheet
const skipWords = [
    'fa-inverse',
    // sizing
    'fa-1x', 'fa-2x', 'fa-3x', 'fa-4x', 'fa-5x', 'fa-6x', 'fa-7x', 'fa-8x', 'fa-9x', 'fa-10x',
    'fa-2xs', 'fa-xs', 'fa-sm', 'fa-lg', 'fa-xl', 'fa-2xl',
    // fixed-width
    'fa-fw',
    // list 直接 <i> には付けないけど
    'fa-ul', 'fa-li',
    // rotating
    'fa-rotate-90', 'fa-rotate-180', 'fa-rotate-270',
    'fa-flip-horizontal', 'fa-flip-vertical', 'fa-flip-both', 'fa-rotate-by',
    // animating
    'fa-beat', 'fa-beat-fade', 'fa-bounce', 'fa-fade', 'fa-flip',
    'fa-shake', 'fa-spin', 'fa-spin-reverse', 'fa-spin-pulse',
    // border
    'fa-border',
    // pull
    'fa-pull-left', 'fa-pull-right',
    // stack 直接 <i> には付けないけど
    'fa-stack', 'fa-stack-1x', 'fa-stack-2x', 'fa-inverse',
    // duotone 専用
    'fa-swap-opacity',
    // accessibility
    'fa-sr-only', 'fa-sr-only-focusable',
]

function analyzeHtmlClass(htmlClass: string): IconRequest[] {
    const classes = htmlClass.split(/\s+/)

    let series: SupportedSeries = 'classic'
    let iconType: SupportedType = 'solid'
    let detected: IconRequest[] = []
    for (let e of classes) {
        if (prefix2series.hasOwnProperty(e)) {
            series = prefix2series[e]
        } else if (prefix2type.hasOwnProperty(e)) {
            iconType = prefix2type[e]
        } else if (!skipWords.includes(e) && e.startsWith('fa-')) {
            detected.push({
                series,
                iconType,
                iconName: e.substring(3),
            })
        }
    }

    return detected
}

type DefaultDynamicImport<T> = () => Promise<{ default: T }>
type DefinitionDynamicImport<T> = () => Promise<{ definition: T }>

type IconModuleMap = Record<string, DefinitionDynamicImport<IconDefinition>>
type ShardedModuleMap = Record<string, DefaultDynamicImport<IconModuleMap>>
type IndexModule = DefaultDynamicImport<ShardedModuleMap> | ShardedModuleMap
type IndexModulesMap = Record<SupportedType, IndexModule[]>

const allModulesIndex: Record<SupportedSeries, IndexModulesMap> = {
    classic: {
        solid: [
            faIndexFreeSolid,
            // () => import(/* webpackChunkName: "fontawesome/fa-index-pro-solid" */'./fa-index-pro-solid'),
        ],
        regular: [
            faIndexFreeRegular,
            // () => import(/* webpackChunkName: "fontawesome/fa-index-pro-regular" */'./fa-index-pro-regular'),
        ],
        brands: [faIndexFreeBrands],
        // thin: [() => import(/* webpackChunkName: "fontawesome/fa-index-pro-thin" */'./fa-index-pro-thin')],
        // light: [() => import(/* webpackChunkName: "fontawesome/fa-index-pro-light" */'./fa-index-pro-light')],
        // duotone: [() => import(/* webpackChunkName: "fontawesome/fa-index-pro-duotone" */'./fa-index-pro-duotone')],
        thin: [],
        light: [],
        duotone: [],
    },
    sharp: {
        // solid: [() => import(/* webpackChunkName: "fontawesome/fa-index-sharp-solid" */'./fa-index-sharp-solid')],
        // regular: [() => import(/* webpackChunkName: "fontawesome/fa-index-sharp-regular" */'./fa-index-sharp-regular')],
        solid: [],
        regular: [],
        brands: [],
        // thin: [() => import(/* webpackChunkName: "fontawesome/fa-index-sharp-thin" */'./fa-index-sharp-thin')],
        // light: [() => import(/* webpackChunkName: "fontawesome/fa-index-sharp-light" */'./fa-index-sharp-light')],
        thin: [],
        light: [],
        duotone: [],
    },
}

async function loadDefinition(icon: IconRequest): Promise<IconDefinition | null> {
    const {series, iconType, iconName} = icon

    if (!allModulesIndex.hasOwnProperty(series)) {
        console.warn(`Unsupported series ${series}`)
        return null
    }

    const imports = allModulesIndex[series]

    if (!imports.hasOwnProperty(iconType)) {
        console.warn(`Unsupported icon type ${iconType} ${series}`)
        return null
    }

    const shardName = iconType + '-' + digest(iconName)

    const indexModules = imports[iconType]
    let lastError = null
    for (let im of indexModules) {
        const indexModule = await resolveIndexModule(im)

        if (!indexModule.hasOwnProperty(shardName)) {
            lastError = `Index not found ${shardName}`
            continue
        }

        const shardedImport = indexModule[shardName]
        const {default: shardedModule} = await shardedImport()
        if (!shardedModule.hasOwnProperty(iconName)) {
            lastError = `Icon not found ${iconName} in ${iconType} ${series}`
            continue
        }

        const iconModule = shardedModule[iconName]
        const {definition: iconDefinition} = await iconModule()

        return iconDefinition
    }

    if (lastError) {
        console.warn(lastError)
    }
    return null
}

async function resolveIndexModule(im: IndexModule): Promise<ShardedModuleMap> {
    if (typeof im !== 'function') {
        return im
    }
    return (await im()).default
}

function digest(name: string) {
    return md5(name).charAt(0)
    // (await crypto.subtle.digest('SHA-1', name)).slice(0, 2).toString();
}
