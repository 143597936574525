(function($) {

    const requestAnimationFrame =
        window['requestAnimationFrame'] ||
        window['mozRequestAnimationFrame'] ||
        window['webkitRequestAnimationFrame'] ||
        window['msRequestAnimationFrame'] ||
        function (fn) {
            setTimeout(fn, 16);
        };

    $.fn.commentFormPositionFix = function(options) {
        options = $.extend({
            'anchorElement': null,
            'scrollControlTarget': window
        }, options);

        const $flexibleArea = $(this);
        const $anchorElement = $(options.anchorElement);
        const $scrollControlTarget = $(options.scrollControlTarget);

        let lastAnchorPosition = null;

        function startPositionFix() {
            if (!$anchorElement.is(':visible')) {
                return;
            }
            lastAnchorPosition = $anchorElement.offset().top - $scrollControlTarget.scrollTop();
            if (lastAnchorPosition > $(window).height()) {
                return;
            }
            // console.log('ready', lastAnchorPosition, $(window).height());
            let cc = 240;
            requestAnimationFrame(function() {
                //console.log('af', lastAnchorPosition);
                tryToFixPosition();
                if (--cc > 0) {
                    requestAnimationFrame(arguments.callee);
                }
            });
        }

        const cancelPositionFixHook = function () {
            // console.log('cancel');
            lastAnchorPosition = null;
        };

        function tryToFixPosition() {
            if (lastAnchorPosition === null) {
                return;
            }
            const scrollPosition = $scrollControlTarget.scrollTop();
            const currentAnchorPosition = $anchorElement.offset().top - scrollPosition;
            if (Math.abs(currentAnchorPosition - lastAnchorPosition) > 2
                && scrollPosition > 0
            ) {
                // console.log('fix', currentAnchorPosition, lastAnchorPosition);
                $scrollControlTarget.scrollTop(
                    scrollPosition + currentAnchorPosition - lastAnchorPosition
                );

                // dirty hack to fix affix
                $('.discussion-aside .info').affix('checkPosition');
            }
        }

        $flexibleArea.on("pjax:start", startPositionFix);

        $(window).on('DOMMouseScroll', cancelPositionFixHook);
        $(window).on('mousewheel', cancelPositionFixHook);
        $(window).on('mousedown', cancelPositionFixHook);
        $(window).on('touchstart', cancelPositionFixHook);
        $(window).on('keywodn', cancelPositionFixHook);
    };
})(jQuery);
