import {AbstractEmojiPalette, PaletteClickCallback, PalettePlacement} from './abstract-emoji-palette'
import '../../css/simple-emoji-palette.css'

export default class SimpleEmojiPalette extends AbstractEmojiPalette{
    static readonly CLASS_NAME = {
        root: 'emoji-palette-button',
        commentActionModal: 'comment-action-modal',
        palette: 'simple-emoji-palette',
        emojiList: 'simple-emoji-list',
        paletteOpen: 'open',
    }

    private static instance?: SimpleEmojiPalette = null

    constructor(
        private emojiList: string[],
        emojiImageUrl: string,
        palettePlacement: PalettePlacement[],
        onClick: PaletteClickCallback,
    ) {
        super(emojiImageUrl, palettePlacement, onClick)
    }

    inExcludedElement(eventTarget: JQuery<EventTarget>): boolean {
        return eventTarget
                .closest('.' + SimpleEmojiPalette.CLASS_NAME.root)
                .is(this.target)
            || eventTarget
                .closest('.' + SimpleEmojiPalette.CLASS_NAME.palette)
                .is(this.palette);
    }

    ensurePaletteContent(): void {
        if (this.palette.children().length === 0) {
            this.palette
                .append(this.renderEmojiList())
        }
    }

    openPalette(): void {
        this.target.addClass(SimpleEmojiPalette.CLASS_NAME.paletteOpen)
        this.palette.addClass(SimpleEmojiPalette.CLASS_NAME.paletteOpen)
    }

    closePalette(): void {
        this.target?.removeClass(SimpleEmojiPalette.CLASS_NAME.paletteOpen)
        this.palette.removeClass(SimpleEmojiPalette.CLASS_NAME.paletteOpen)
    }

    private render() {
        const modal = $('<div></div>')
            .addClass(SimpleEmojiPalette.CLASS_NAME.palette)

        this.palette = modal

        $('body').append(modal)
    }

    private renderEmojiList(): JQuery {
        const palette = $('<ul></ul>')
            .addClass(SimpleEmojiPalette.CLASS_NAME.emojiList)

        this.emojiList.filter(e => e !== null).forEach(emoji => {
            palette.append(this.emojiButton(emoji))
        })

        return palette
    }

    static getInstance(
        emojiList: string[],
        emojiImageUrl: string,
        palettePlacement: PalettePlacement[],
        onClick: PaletteClickCallback
    ): SimpleEmojiPalette {
        if (this.instance === null) {
            this.instance = new SimpleEmojiPalette(emojiList, emojiImageUrl, palettePlacement, onClick)
        }

        this.instance.render()

        return this.instance
    }
}
