const WIKIWIKI_COLOR_DEFAULTS = {
    bg: 'FFFFFF',
    // c1: '2183B4',
    fs: '12',
}

interface EmbedTagEditorOptions {
    iframeId: string
}

$.fn.extend({
    'embedTagEditor': function (options:EmbedTagEditorOptions) {
        options = $.extend({
            iframeId: "",
        }, options)

        const $container = $(this)

        const zcommentBaseParams = $container.find(".base-wikiwiki-params").val()?.toString()
        const previewBaseUrl = $container.find(".preview-base-url").val()?.toString()

        const $fieldStyle = $container.find('.tag-style select')
        const $fieldSize = $container.find('.tag-size input')
        const $fieldHeight = $container.find('.tag-height input')

        const $fieldColorBg = $container.find('.tag-color-bg input')
        // const $fieldColor1 = $container.find('.tag-color-1 input')
        // const $fieldColor2 = $container.find('.tag-color-2 input')
        const $fieldFontSize = $container.find('.tag-fs input')

        const $code = $container.find('.embed-code')

        $fieldColorBg.attr('placeholder', WIKIWIKI_COLOR_DEFAULTS.bg)
        // $fieldColor1.attr('placeholder', WIKIWIKI_COLOR_DEFAULTS.c1)
        // $fieldColor2.attr('placeholder', null)
        $fieldFontSize.attr('placeholder', WIKIWIKI_COLOR_DEFAULTS.fs)

        function collectParams() {
            const params = new URLSearchParams()
            const height = parseInt($fieldHeight.val()?.toString())
            if (!isNaN(height)) {
                params.append('h', height.toString())
            }
            const style = $fieldStyle.val()?.toString()
            if (style && style !== 'default') {
                params.append('style', style)
            }
            const size = parseInt($fieldSize.val()?.toString())
            if (!isNaN(size)) {
                params.append('size', size.toString())
            }
            const bg = $fieldColorBg.val()?.toString().trim()
            if (isColorCode(bg)) {
                params.append('bg', bg)
            }
            // const c1 = $fieldColor1.val()?.toString().trim()
            // if (isColorCode(c1)) {
            //     params.append('c1', c1)
            // }
            // const c2 = $fieldColor2.val()?.toString().trim()
            // if (isColorCode(c2)) {
            //     params.append('c2', c2)
            // }
            const fs = Number($fieldFontSize.val())
            if (fs !== 0 && !isNaN(fs)) {
                params.append('fs', fs.toString())
            }
            return params
        }

        function createZcommentDesc() {
            const params = collectParams()
            return "#zcomment(" + zcommentBaseParams + '&' + params.toString() + ")"
        }

        $container.find('input,select').on('change keyup click', function () {
            $code.text(createZcommentDesc())
        }).trigger('change')

        // preview
        const $window = $("#preview-window")
        const $bgSelectRadio = $window.find('#preview-window-bg-select input')
        const $previewContentFrame = $window.find('iframe')

        function previewBgColor() {
            return $bgSelectRadio.filter(':checked').val().toString()
        }

        function createPreviewUrl() {
            const params = collectParams()
            if (params.has('h')) {
                params.delete('h')
            }
            if (previewBgColor() === '#000000') {
                if (!params.has('style') || params.get('style') === 'default') {
                    params.set('style', 'default-dark')
                } else if (params.get('style') === 'wikiwiki') {
                    params.set('skin', 'default_black01')
                }
            }
            return previewBaseUrl + (previewBaseUrl.indexOf('?') === -1 ? '?' : '&') + params.toString()
        }

        function loadPreviewContent() {
            $previewContentFrame.attr('src', createPreviewUrl())
        }

        $bgSelectRadio.on('change', function () {
            // ウィンドウが出ておらず操作しようがない場合にもchangeイベントがトリガ〜されることがある。
            // そのしで見えないところに iframe を作成して無駄に動いてしまうのを防ぐ必要がある。
            if (!$window.is(':visible')) {
                return
            }
            $window.find('.modal-body').css({
                backgroundColor: previewBgColor()
            })
            loadPreviewContent()
        }).trigger('change')

        $container.find(".preview-toggle").on("click", function () {
            const h = parseInt($fieldHeight.val()?.toString())
            $window.find('iframe')
                .attr('src', 'about:blank')
                .css('height', !isNaN(h) ? h : 100)
            setTimeout(function () {
                // @ts-ignore
                $window.modal('show')
                loadPreviewContent()
                $previewContentFrame.one('load', function () {
                    iFrameResize({
                        heightCalculationMethod: 'lowestElement',
                    }, '#' + options.iframeId)
                    //setTimeout(function() {
                    //    $window.modal('adjustBackdrop');
                    //}, 500);
                })
            }, 100)
        })

        $window.on('shown.bs.modal', function () {
            //$(this).modal('adjustBackdrop');
        })
    },
})

function isColorCode(v?: string) {
    if (!v) {
        return false
    }
    return v.match(/^([\dA-Fa-f]{6}|[\dA-Fa-f]{3})$/) !== null
}
