(function($) {

    $.fn.expandCommentRef = function(options) {
        options = $.extend({
            headerAnchor: false
        }, options);

        function createReferencedCommentsArea() {
            const $area = $('<div>').addClass('referenced-comments-area');
            const $closeButton = $('<button type="button" class="close">×</button>');

            $area.append($('<div>').addClass('comments-head').append($closeButton));
            $area.append($('<div>').addClass('comments-body'));

            $closeButton.on('click', function() {
                $area.hide();
            });

            return $area;
        }

        function getReferencedCommentsArea($anchorLink) {
            let $referencedCommentsArea;
            let $contentBody;

            $referencedCommentsArea = $anchorLink.parents('.referenced-comments-area');

            if ($referencedCommentsArea.length === 0) {
                if (options.headerAnchor) {
                    $contentBody = $anchorLink.parents('.header').next();
                    $referencedCommentsArea = $contentBody.find('>*:first-child');
                    // console.log($contentBody, $referencedCommentsArea);
                    if (!$referencedCommentsArea.is('.referenced-comments-area')) {
                        $referencedCommentsArea = createReferencedCommentsArea().prependTo($contentBody);
                    }
                } else {
                    $contentBody = $anchorLink.parents('.content:first').find('.body:first');

                    let $parentParagraph = null;
                    $contentBody.find('> *').each(function () {
                        if ($.contains(this, $anchorLink.get(0))) {
                            $parentParagraph = $(this);
                        }
                    });
                    $referencedCommentsArea = $parentParagraph.prev();
                    if (!$referencedCommentsArea.is('.referenced-comments-area')) {
                        $referencedCommentsArea = createReferencedCommentsArea().insertBefore($parentParagraph);
                    }
                }
            }
            $referencedCommentsArea.show();

            return $referencedCommentsArea;
        }

        function findOrderedPosition($referencedCommentsArea, href) {
            const currentNumber = href2number(href);

            const $firstNode = $referencedCommentsArea.find('.expanded-comment-ref:first-child');
            if ($firstNode.length === 0) {
                return null;
            }
            const firstNumber = href2number($firstNode.data('href'));
            if (currentNumber <= firstNumber) {
                return null;
            }

            let prevNode = null;
            let posNode = null;
            $referencedCommentsArea.find('.expanded-comment-ref').each(function() {
                if (posNode) {
                    return;
                }
                const $ref = $(this);
                const n = href2number($ref.data('href'));

                if (n > currentNumber) {
                    posNode = prevNode;
                    return;
                }
                prevNode = $ref;
            });

            if (posNode) {
                return posNode;
            } else {
                return prevNode;
            }
        }

        function fixUpExpandedContent($ref) {
            window.lazyload_fix.done($ref);
            window.hide_comments.update($ref);
            $ref.find('.hider-unhide').remove();
            $ref.find('.emoji-text').emojify();
            $ref.find('.paragraph-collapse').paragraphCollapse();
            $ref.find('.header .comment-parent-link').expandCommentRef({headerAnchor: true}); // ストレートでのみにしたいところ
            $ref.find('.body .comment-parent-link').expandCommentRef();
            Prism.highlightAll();
        }

        function isReferenceExists($referencedCommentsAreaBody, href) {
            let exists = false;
            $referencedCommentsAreaBody.find('> .expanded-comment-ref').each(function () {
                if ($(this).data('href') === href) {
                    exists = true;
                }
            });
            return exists;
        }

        function insertCommentsTo($targetAreaBody, href, $content) {
            const $insertPosition = findOrderedPosition($targetAreaBody, href);
            if ($insertPosition) {
                $content.insertAfter($insertPosition);
            } else {
                $content.prependTo($targetAreaBody);
            }
            fixUpExpandedContent($content);
        }

        function href2number(href) {
            const tmp = href.split('/');
            return Number(tmp[tmp.length - 1]);
        }

        function errorContent(href) {
            const currentNumber = href2number(href);
            return $('<div>').addClass('expanded-comment-ref').data('href', href).append(
                $('<span>').css({
                    'font-size': '11px',
                    'white-space': 'nowrap',
                    'color': '#aaaaaa',
                    'margin': '0 1em 0 0'
                }).text(currentNumber)
            ).append(
                $('<span>').addClass('text-muted').text("コメントを参照できませんでした。")
            );
        }

        $(this).on('click', function(event) {
            event.preventDefault();

            const $anchorLink = $(this);
            const href = $anchorLink.attr('href');
            if (!href || !href.match(/\/.+?\/topic\/\d+\/\d+/)) {
                return;
            }

            const $referencedCommentsArea = getReferencedCommentsArea($anchorLink);
            const $referencedCommentsAreaBody = $referencedCommentsArea.find('.comments-body');

            if (!isReferenceExists($referencedCommentsAreaBody, href)) {
                $.get(href + '/inline', function(data) {
                    insertCommentsTo(
                        $referencedCommentsAreaBody,
                        href,
                        $('<div>').addClass('expanded-comment-ref').data('href', href).append(data)
                    );
                }).fail(function() {
                    insertCommentsTo(
                        $referencedCommentsAreaBody,
                        href,
                        errorContent(href)
                    );
                });
            }
        });
    };
})(jQuery);
