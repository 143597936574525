/**
 * Custom auth choice widget.
 */
(function($) {

    $.fn.customAuthchoice = function(options) {
        options = $.extend({
            triggerSelector: 'button.auth-link',
            popup: {
                resizable: 'yes',
                scrollbars: 'no',
                toolbar: 'no',
                menubar: 'no',
                location: 'no',
                directories: 'no',
                status: 'yes',
                width: 450,
                height: 380
            }
        }, options);

        return this.each(function() {
            const $container = $(this);

            $container.find(options.triggerSelector).on('click', function(e) {
                e.preventDefault();

                let authChoicePopup = $container.data('authChoicePopup');

                if (authChoicePopup) {
                    authChoicePopup.close();
                }

                const url = jQuery(this).data('url');
                const popupOptions = $.extend({}, options.popup); // clone

                const localPopupWidth = this.getAttribute('data-popup-width');
                if (localPopupWidth) {
                    popupOptions.width = localPopupWidth;
                }
                const localPopupHeight = this.getAttribute('data-popup-height');
                if (localPopupWidth) {
                    popupOptions.height = localPopupHeight;
                }

                popupOptions.left = (window.screen.width - popupOptions.width) / 2;
                popupOptions.top = (window.screen.height - popupOptions.height) / 2;

                const popupFeatureParts = [];
                for (const propName in popupOptions) {
                    if (popupOptions.hasOwnProperty(propName)) {
                        popupFeatureParts.push(propName + '=' + popupOptions[propName]);
                    }
                }
                const popupFeature = popupFeatureParts.join(',');

                authChoicePopup = window.open(url, 'yii_auth_choice', popupFeature);
                authChoicePopup.focus();

                $container.data('authChoicePopup', authChoicePopup);
            });
        });
    };

})(jQuery);
