/**
 * クライアントサイドで特定の投稿者のコメントを隠すやつ
 */
import TargetedConfirmModal from './targeted-confirm-modal'

const CLASSNAME = {
    HIDER_WRAPPER: 'hider-wrapper',
    HIDER_BUTTON: 'hider-button',
    MUTE_BUTTON: 'mute-button',
    COMMENT_ACTION_MENU_BUTTON: 'comment-action-button',
    TOOLTIP: 'tooltip-for-menu',
    COMMENT_INLINE: 'comment-inline',
    MUTE_CONFIRM_MODAL: 'mute-confirm-modal',
}
const SELECTOR = {
    COMMENT: '.comment',
    MENU: '.comment-action-menu',
    CONTENT: '.content',
    INLINE_CONTENT: '.inline-content',
    HASHED_TRACK_INFO: '.hashed-track-info',
    POSTED_USER_ACCOUNT: '.posted-user-account',
    HIDER_WRAPPER: '.' + CLASSNAME.HIDER_WRAPPER,
    HIDER_BUTTON: '.' + CLASSNAME.HIDER_BUTTON,
    MUTE_BUTTON: '.' + CLASSNAME.MUTE_BUTTON,
    TOOLTIP: '.' + CLASSNAME.TOOLTIP,
    OFFICIAL_COMMENT: '.official-comment',
    COMMENT_INLINE: '.' + CLASSNAME.COMMENT_INLINE,
}
const DATA_KEY = {
    HASHED_TRACKING_COOKIE: 'hashed-tracking-cookie',
    TRIP: 'trip',
    HIDER_KEY: 'hider-key',
}
const COOKIE_KEY = {
    IgnoreHashedTrackingCookie: 'IgnoreHashedTrackingCookie',
    IgnoreAccountTrip: 'IgnoreAccountTrip',
}

interface IgnoreTargetData {
    ignore_account_trip: string[]
    ignore_hashed_tracking_cookie: string[]
}

type embedStyle = 'default' | 'wikiwiki'

interface SelfAccountInfo {
    trip: string | null
    hashedTrackingCookie: string | null
}

class HideComments {

    __persistentManager: PersistentManager

    _container?: JQuery = null

    _style: embedStyle = 'default'

    _selfAccountInfo: SelfAccountInfo

    _displayGuestTrackInfoNow: boolean

    constructor(_persistentManager: PersistentManager) {
        this.__persistentManager = _persistentManager
    }

    /**
     * $container 内の投稿に無視リストを適用する。
     */
    start(
        $container: JQuery,
        style: embedStyle,
        displayGuestTrackInfoNow: boolean,
        ignoreTargetSaveUrl?: string,
        ignoreTargetData?: IgnoreTargetData,
        selfAccountInfo?: SelfAccountInfo,
    ) {
        if (this._container) {
            // すでに初期化済みなら重複してかからないようにする。更新のときは update を使いましょう。
            return
        }
        this._container = $container
        this.__persistentManager._save_endpoint = ignoreTargetSaveUrl
        this.__persistentManager._initial_data = ignoreTargetData
        this._style = style
        this._displayGuestTrackInfoNow = displayGuestTrackInfoNow
        this._selfAccountInfo = selfAccountInfo || {trip: null, hashedTrackingCookie: null}
        this._apply()

        this._cancelVisibilityControlTimeout($container)
        $container.css('visibility', 'visible')
    }

    /**
     * $container 内の投稿に無視リストを再適用する。
     */
    update($container: JQuery) {
        this._container = $container
        this._apply()

        this._cancelVisibilityControlTimeout($container)
        $container.css('visibility', 'visible')
    }

    /**
     * 無視リストを再適用するDOMをロードする前にコールしておく。DOM操作が見えると汚いので。
     */
    prepare($container: JQuery) {
        this._cancelVisibilityControlTimeout($container)

        $container.css('visibility', 'hidden')

        // hiddenのまま放置されるといけないので3秒で強制解除
        this._startVisibilityControlTimeout($container, () => {
            $container.css('visibility', 'visible')
        }, 3000)
    }

    _startVisibilityControlTimeout($container: JQuery, fn: ()=>any, delay: number) {
        $container.data('visibility-control-timeout', setTimeout(() => {
            $container.data('visibility-control-timeout', null)
            fn()
        }, delay))
    }

    _cancelVisibilityControlTimeout($container: JQuery) {
        const timeout = $container.data('visibility-control-timeout')
        if (timeout) {
            clearTimeout(timeout)
            $container.data('visibility-control-timeout', null)
        }
    }

    _apply() {
        const persistentManager = this.__persistentManager
        const $container = this._container
        const style = this._style
        const displayGuestTrackInfoNow = this._displayGuestTrackInfoNow
        const selfAccountInfo = this._selfAccountInfo

        /**
         * $target の上位にあたる div.content を「非表示」のプレースホルダに置き換える。プレースホルダ内には解除ボタン（リンク）を含める。
         */
        function hideOne($target: JQuery, _label: string, _key: string, _cookieRemoveFunc: ()=>any) {
            // console.log('hideOne:', _type, _key)
            let $targetContent = $target.closest(SELECTOR.CONTENT)
            const $inlineContent = $target.closest(SELECTOR.INLINE_CONTENT)
            const isInline = $inlineContent.length > 0
            let commentClass = '.comment'
            if (isInline) {
                $targetContent = $inlineContent
                commentClass = SELECTOR.COMMENT_INLINE
            }

            $targetContent.each(function() {
                const $content = $(this)

                $content.closest(commentClass).addClass('hider-hidden-comment'); // タイムラインモードではブロックごと非表示に

                let unhideDisplayDelay = null
                $content.wrap(
                    $('<div>')
                        .addClass('clearfix ' + CLASSNAME.HIDER_WRAPPER)
                        .data(DATA_KEY.HIDER_KEY, _key)
                        .on('mouseenter', function() {
                            const self = this
                            unhideDisplayDelay = setTimeout(() => {
                                unhideDisplayDelay = null
                                $(self).find('.hider-unhide').show().trigger('blur')
                            }, 200)
                        }).on('mouseleave', function() {
                            if (unhideDisplayDelay) {
                                clearTimeout(unhideDisplayDelay)
                            }
                            unhideDisplayDelay = null
                            $(this).find('.hider-unhide').hide().trigger('blur')
                        })
                )
                $content.before(
                    $('<small>').addClass('hider-hidden-content').text('ミュートコメント')
                )
                $content.before(
                    $('<a>')
                        .addClass('hider-unhide')
                        .attr('href', '#')
                        .hide()
                        .append(
                            $('<small>').text(_label + ' のミュートを解除')
                        )
                        .on('click', function(e){
                            e.preventDefault()
                            _cookieRemoveFunc()
                            $container.find(SELECTOR.HIDER_WRAPPER).each(function(){
                                const $wrapper = $(this)
                                const contentClass = $wrapper.parent().hasClass(CLASSNAME.COMMENT_INLINE)
                                                        ? SELECTOR.INLINE_CONTENT
                                                        : SELECTOR.CONTENT
                                if (_key === $wrapper.data(DATA_KEY.HIDER_KEY)) {
                                    const wrappedContent = $wrapper.find(contentClass)

                                    if (wrappedContent.length === 1) {
                                        $wrapper.replaceWith(wrappedContent)
                                    }
                                }
                            })
                        })
                )
                $content.wrap(
                    $('<div>').hide()
                )
            })
        }

        /**
         * jQuery の .data() の暗黙型変換の影響を受けないバージョン
         */
        function dataAsString($element: JQuery, field: string) {
            return $element.attr('data-' + field)
        }

        /**
         * $container 内にある、追跡クッキーが合致するコメントを全て非表示にする。
         */
        function hideAllByHashedTrackingCookie(_cookie: string) {
            $container.find(SELECTOR.HASHED_TRACK_INFO).each(function(this: HTMLElement){
                const $target = $(this)
                if (_cookie === dataAsString($target, DATA_KEY.HASHED_TRACKING_COOKIE)) {
                    hideOne($target, _cookie + '@xxxxx', _cookie, function(){
                        persistentManager.removeIgnoreHashedTrackingCookie(_cookie)
                    })
                }
            })
        }

        /**
         * $container 内にある、アカウントトリップが合致するコメントを全て非表示にする。
         */
        function hideAllByAccountTrip(_trip: string) {
            $container.find(SELECTOR.POSTED_USER_ACCOUNT).each(function(){
                const $target = $(this)
                if (_trip === dataAsString($target, DATA_KEY.TRIP)) {
                    hideOne($target, _trip, _trip, function(){
                        persistentManager.removeIgnoreAccountTrip(_trip)
                    })
                }
            })
        }

        /**
         * $header に右寄せで無視ボタンを挿入する。
         * 無視ボタンが押された時に実行される関数を指定するべし。
         */
        function applyHider($header: JQuery, style: embedStyle, label: string, clickFunc: () => any) {
            if (style === 'wikiwiki') {
                $header.find(SELECTOR.HIDER_BUTTON).attr('title', label).on('click',function(e) {
                    e.preventDefault()
                    clickFunc()
                })
            } else {
                const $menu = $header.closest(SELECTOR.COMMENT).children(SELECTOR.CONTENT).children(SELECTOR.MENU)

                if ($menu.find(SELECTOR.MUTE_BUTTON).length > 0) {
                    return;
                }

                const $button = $('<button></button>')
                        .attr('type', 'button')
                        .attr('tabindex', '-1')
                        .append('<i class="fa-regular fa-message-slash"></i><span class="action-description">ミュート</span>')
                        .on('click', function (e) {
                            e.preventDefault()
                            $(document).trigger('action-menu-button-click', {
                                actionType: 'mute',
                                origin: this
                            })
                            clickFunc()
                        })

                const $muteButtonRoot = $('<div></div>')
                    .addClass(
                        CLASSNAME.MUTE_BUTTON + ' '
                        + CLASSNAME.COMMENT_ACTION_MENU_BUTTON + ' '
                        + TargetedConfirmModal.CLASS_NAME.modalTarget
                    )
                    .append($button)

                $menu.append($muteButtonRoot)
            }
        }

        /**
         * 追跡OFF設定のときはゲストユーザーはミュートできないので、
         * その旨ユーザーに伝えるためのメッセージを表示する
         * @param $header
         * @param msg
         */
        function displayCantMuteReason($header: JQuery, msg: string) {
            const menu = $header.closest(SELECTOR.COMMENT).children(SELECTOR.CONTENT).children(SELECTOR.MENU)
            const button = menu.find(SELECTOR.MUTE_BUTTON)
            let tooltip = menu.find(SELECTOR.TOOLTIP)
            if (tooltip.length < 1) {
                tooltip = $(`<span class="${CLASSNAME.TOOLTIP}"></span>`)
                menu.append(tooltip)
            }

            tooltip.text(msg)

            const buttonLeft = button.position().left
            const buttonWidth = button.outerWidth()
            const tooltipHeight = tooltip.outerHeight()
            const tooltipWidth = tooltip.outerWidth()

            const arrowHeight = 5

            // top は ツールチップの高さ + 矢印の高さ分だけ メニューのミュートボタンの上の位置に、
            // left はボタンの右端とツールチップの右端が一致するように
            tooltip
                .css('top', -1 * tooltipHeight - arrowHeight)
                .css('left', buttonLeft + buttonWidth - tooltipWidth)
                .fadeIn('fast');

            // zawazawaスタイルのzcommentで一番上のコメントにたいしてツールチップを出すと
            // 上が途切れる場合がある
            if (tooltip.offset().top < 0) {
                tooltip
                    .css({
                        top: 'auto',
                        bottom: -1 * tooltipHeight - arrowHeight,
                    })
                    .addClass('up-arrow');
            }
        }

        // 追跡クッキーまたはアカウントトリップを持つコメントそれぞれに、無視ボタンを付加する。
        if (style === 'wikiwiki') {
            // wikiwikiスタイルの埋め込みは今まで通り

            $container.find('.comment > .content > .body .hidable').each(function() {
                const $header = $(this)

                const _trackInfo = $header.find(SELECTOR.HASHED_TRACK_INFO)
                const _cookie = dataAsString(_trackInfo, DATA_KEY.HASHED_TRACKING_COOKIE)
                const _account = $header.find(SELECTOR.POSTED_USER_ACCOUNT)
                const _trip = dataAsString(_account, DATA_KEY.TRIP)

                const mutableGuestComment = _cookie && _cookie !== selfAccountInfo.hashedTrackingCookie
                const mutableLoginComment = _trip && _trip !== selfAccountInfo.trip

                if (mutableGuestComment) {
                    applyHider($header, 'wikiwiki',_cookie + '@xxxxx を表示しない', () => {
                        TargetedConfirmModal.confirm(
                            $(this),
                            _cookie + ' @xxxxx のコメントをミュートしますか?',
                            ['top', 'right'],
                            () => {
                                hideAllByHashedTrackingCookie(_cookie)
                                persistentManager.addIgnoreHashedTrackingCookie(_cookie)
                            },
                            null,
                            {
                                okButton: 'btn btn-sm btn-danger'
                            }
                        )
                    })


                } else if (mutableLoginComment) {
                    applyHider($header, 'wikiwiki', _trip + ' を表示しない', () => {
                        TargetedConfirmModal.confirm(
                            $(this),
                            _trip + ' のコメントをミュートしますか?',
                            ['top', 'right'],
                            () => {
                                hideAllByAccountTrip(_trip)
                                persistentManager.addIgnoreAccountTrip(_trip)
                            },
                            null,
                            {
                                okButton: 'btn btn-sm btn-danger'
                            }
                        )
                    })
                } else {
                    $header.remove()
                }
            })
        } else {
            $container.find('.comment > .content > .comment-header-body-container > .header').each(function() {
                const $header = $(this)

                const _trackInfo = $header.find(SELECTOR.HASHED_TRACK_INFO)
                const _cookie = dataAsString(_trackInfo, DATA_KEY.HASHED_TRACKING_COOKIE)
                const _account = $header.find(SELECTOR.POSTED_USER_ACCOUNT)
                const _trip = dataAsString(_account, DATA_KEY.TRIP)

                const existsTrackInfo = _trackInfo.length > 0 && _cookie
                const existsAccountInfo = _account.length > 0 && _trip

                const official = $header.find(SELECTOR.OFFICIAL_COMMENT).length > 0

                // 自分のコメント、もしくは公式のコメントならミュートボタンを出さない
                if (
                    existsTrackInfo && _cookie === selfAccountInfo.hashedTrackingCookie
                    || existsAccountInfo && _trip === selfAccountInfo.trip
                    || official
                ) {
                    return;
                }

                // 追跡情報がなかったらミュートできない旨表示
                let clickFunc = () => {
                    const msg = displayGuestTrackInfoNow
                        ? 'このコメントは保護されています。ミュートできません。'
                        : 'ゲストユーザーの情報を保護する設定になっているためミュートできません。'
                    displayCantMuteReason($header, msg)
                }

                if (existsTrackInfo) {
                    clickFunc = () => {
                        const $muteButton = $header.closest(SELECTOR.COMMENT)
                            .children(SELECTOR.CONTENT)
                            .children(SELECTOR.MENU)
                            .find(SELECTOR.MUTE_BUTTON)

                        TargetedConfirmModal.confirm(
                            $muteButton,
                            _cookie + ' @xxxxx のコメントをミュートしますか?',
                            ['top', 'right'],
                            () => {
                                hideAllByHashedTrackingCookie(_cookie)
                                persistentManager.addIgnoreHashedTrackingCookie(_cookie)
                            },
                            null,
                            {
                                okButton: 'btn btn-sm btn-danger'
                            }
                        )
                    }
                } else if (existsAccountInfo) {
                    clickFunc = () => {
                        const $muteButton = $header.closest(SELECTOR.COMMENT)
                            .children(SELECTOR.CONTENT)
                            .children(SELECTOR.MENU)
                            .find(SELECTOR.MUTE_BUTTON)

                        TargetedConfirmModal.confirm(
                            $muteButton,
                            _trip + ' のコメントをミュートしますか?',
                            ['top', 'right'],
                            () => {
                                hideAllByAccountTrip(_trip)
                                persistentManager.addIgnoreAccountTrip(_trip)
                            },
                            null,
                            {
                                okButton: 'btn btn-sm btn-danger'
                            }
                        )
                    }
                }
                applyHider($header, 'default', '', clickFunc)
            })
        }

        // クッキーに保存されている無視対象の追跡クッキーとアカウントトリップを、全て非表示にする。
        persistentManager.read(function(){
            $.each(Object.keys(persistentManager.ignore_account_trip), function(i, _trip){
                hideAllByAccountTrip(_trip)
            })
            $.each(Object.keys(persistentManager.ignore_hashed_tracking_cookie), function(i, _cookie){
                hideAllByHashedTrackingCookie(_cookie)
            })
        })

    }
}

class PersistentManager {
    _save_endpoint?: string = null

    _initial_data?: IgnoreTargetData = null

    ignore_hashed_tracking_cookie: object = {}
    ignore_account_trip: object = {}

    isGuest() {
        return (this._save_endpoint == null)
    }

    addIgnoreHashedTrackingCookie(value: string) {
        this.ignore_hashed_tracking_cookie[value] = true
        if (this.isGuest()) {
            this.saveCookie(COOKIE_KEY.IgnoreHashedTrackingCookie, this.ignore_hashed_tracking_cookie)
        } else {
            this.saveServer()
        }
    }

    addIgnoreAccountTrip(value: string) {
        this.ignore_account_trip[value] = true
        if (this.isGuest()) {
            this.saveCookie(COOKIE_KEY.IgnoreAccountTrip, this.ignore_account_trip)
        } else {
            this.saveServer()
        }
    }

    removeIgnoreHashedTrackingCookie(value: string) {
        delete this.ignore_hashed_tracking_cookie[value]
        if (this.isGuest()) {
            this.saveCookie(COOKIE_KEY.IgnoreHashedTrackingCookie, this.ignore_hashed_tracking_cookie)
        } else {
            this.saveServer()
        }
    }

    removeIgnoreAccountTrip(value: string) {
        delete this.ignore_account_trip[value]
        if (this.isGuest()) {
            this.saveCookie(COOKIE_KEY.IgnoreAccountTrip, this.ignore_account_trip)
        } else {
            this.saveServer()
        }
    }

    read(callback: ()=>any) {
        if (this.isGuest()) {
            this.readCookie()
        } else {
            this.readInitial()
        }
        callback()
    }

    saveServer() {
        const endpoint = this._save_endpoint
        const data = {
            ignore_account_trip: Object.keys(this.ignore_account_trip),
            ignore_hashed_tracking_cookie: Object.keys(this.ignore_hashed_tracking_cookie)
        }
        $.ajax({
            url: endpoint,
            type: 'POST',
            data: data,
            dataType: 'json'
        }).done((data) => {
            if (data && data.hasOwnProperty('status') && data.status === 'error' && data.hasOwnProperty('message')) {
                alert('ミュート設定を保存できませんでした。【' + data.message + '】\n設定の状態を確認するには、ページを再読み込みしてください。')
            }
        }).fail(() => {
            alert('通信エラーのため、ミュート設定を保存できませんでした。\n設定の状態を確認するには、ページを再読み込みしてください。')
        })
    }

    saveCookie(name: string, object: object) {
        let cookieValue
        const keyArray = Object.keys(object)
        if (keyArray.length > 0) {
            let buffer = ''
            $.each(keyArray, (i, v) => {
                if (i > 0) buffer += ','
                buffer += v
            })
            cookieValue = name + '=' + encodeURIComponent(buffer) + ';max-age=' + (60 * 60 * 24 * 30)
        } else {
            cookieValue = name + '=;max-age=0'
        }
        document.cookie = cookieValue
        document.cookie = cookieValue + ';SameSite=None;Secure'; // Chrome 対策
    }

    readInitial() {
        const self = this
        const data = this._initial_data
        if (data && data.hasOwnProperty('ignore_account_trip')) {
            if (Array.isArray(data.ignore_account_trip)) {
                $.each(data.ignore_account_trip, (i, v) => {
                    self.ignore_account_trip[v] = true
                })
            }
        }
        if (data && data.hasOwnProperty('ignore_hashed_tracking_cookie')) {
            if (Array.isArray(data.ignore_hashed_tracking_cookie)) {
                $.each(data.ignore_hashed_tracking_cookie, (i, v) => {
                    self.ignore_hashed_tracking_cookie[v] = true
                })
            }
        }
    }

    readCookie() {
        const self = this
        const cookie = document.cookie
        if (!(cookie && cookie !== '')) {
            return;
        }

        $.each(cookie.split(';'), function (i, v) {
            const c = v.trim().split('=')
            if (c.length === 2) {
                const _cookieKey = c[0]
                const _cookieValue = c[1]

                if (_cookieKey === COOKIE_KEY.IgnoreHashedTrackingCookie) {
                    $.each(decodeURIComponent(_cookieValue).split(','), function (ii, vv) {
                        self.ignore_hashed_tracking_cookie[vv] = true
                    })
                } else if (_cookieKey === COOKIE_KEY.IgnoreAccountTrip) {
                    $.each(decodeURIComponent(_cookieValue).split(','), function (ii, vv) {
                        self.ignore_account_trip[vv] = true
                    })
                }
            }
        })
    }
}

window['hide_comments'] = new HideComments(new PersistentManager())
