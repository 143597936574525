import {faUseIcons} from '../lib/fakit'

export async function requireIconsFor(target: JQuery) {
    const classes = collectIconClasses(target)
    await faUseIcons(classes)
}

function collectIconClasses(jq: JQuery) {
    const $icons = jq.find('[class*="fa-"]').addBack('[class*="fa-"]')
    const classes = $icons.map(function () {
        return $(this).attr('class')
    }).toArray()
    const normalizedClasses = classes.map((cs) => {
        return cs.split(/\s+/).filter((c) => {
            return c.match(/^(fa[srb]|fa-.+)$/) !== null
        }).join(" ")
    })
    // unique array
    return Array.from(new Set(normalizedClasses))
}
