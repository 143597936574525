(function($) {
    $.fn.pageViewCounter = function(options) {
        options = $.extend({
            url: null,
            totalSelector: null,
            popupDetail: false
        }, options);

        const $container = $(this);

        $.ajax(options.url, {
            //cache: false
        }).done(function(data) {
            if (options.totalSelector) {
                $container.find(options.totalSelector).text(data['total']);
            }
            if (options.popupDetail) {
                $container.tooltip({
                    placement: "top",
                    html: true,
                    title: "今日: " + data['today'] + " <br> 昨日: " + data['yesterday']
                });
            }
        });
    };

    $.fn.watchToggler = function(options) {
        options = $.extend({
            isGuest: true,
            isWatched: false,
            buttonSelector: null
        }, options);

        const $container = $(this);

        let $innerContent;
        if (options.isGuest) {
            $innerContent = 'ログインするとフォロー機能が使えます。';
        } else {
            const _button = $(options.buttonSelector);
            if (_button.length !== 1) {
                return;
            }
            $innerContent = _button.removeClass('btn btn-default btn-sm');
        }

        const $tooltip = $('<div>').addClass('tooltip fade top out')
            .hide() // 不可視状態のボタンを意図せずクリックしてしまうのを防止
            .css('z-index', 999) // 初期値の 1070 だと ナビゲーションのドロップダウン（1000）を覆ってしまい、ログアウトボタンが押せない。
            .append(
                $('<div>').addClass('tooltip-arrow').css('left', '50%')
            )
            .append(
                $('<div>').addClass('tooltip-inner').append($innerContent)
            );

        $container.append($tooltip)
            .hover(function(){
                $tooltip.removeClass('out').addClass('in').stop().show();
                // 位置調整
                const top = $container.position().top - ($container.innerHeight() - $container.height() + $tooltip.height());
                const left = $container.position().left + ($container.innerWidth() - $tooltip.outerWidth()) / 2;
                $tooltip.css('top', top).css('left', left);
            }, function(){
                $tooltip.removeClass('in').addClass('out').delay(500).queue(function(){
                    $(this).hide().dequeue();
                });
            });

    };

})(jQuery);
