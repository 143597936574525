(function($) {
    $.fn.viewModeNav = function(options) {
        options = $.extend({
            itemsContainerSelector: null,
            viewModeField: null
        }, options);

        const $form = $(this);
        const itemsContainerSelector = options.itemsContainerSelector;
        const $viewModeField = $form.find(options.viewModeField);

        $form.find('.btn-toggle .btn').on('click', function() {
            const $triggeredButton = $(this);
            const $buttons = $triggeredButton.siblings().add($triggeredButton);
            const $active = $buttons.filter('.active');
            const $inactive = $buttons.not($active);
            $active.removeClass('active').removeClass('btn-primary').addClass('btn-default');
            $inactive.addClass('active').removeClass('btn-default').addClass('btn-primary');

            $viewModeField.val($triggeredButton.data('mode'));
            $form.submit();
        });

        $form.on('submit', function(event) {
            $.pjax.submit(event, itemsContainerSelector, {
                push: false,
                replace: false,
                timeout: 0,
                scrollTo: null
            });
        });
    };
})(jQuery);
