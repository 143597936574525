export default {
    'regular-0': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-0" */'./fa-index-free-regular-0'),
    'regular-1': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-1" */'./fa-index-free-regular-1'),
    'regular-2': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-2" */'./fa-index-free-regular-2'),
    'regular-3': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-3" */'./fa-index-free-regular-3'),
    'regular-4': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-4" */'./fa-index-free-regular-4'),
    'regular-5': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-5" */'./fa-index-free-regular-5'),
    'regular-6': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-6" */'./fa-index-free-regular-6'),
    'regular-7': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-7" */'./fa-index-free-regular-7'),
    'regular-8': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-8" */'./fa-index-free-regular-8'),
    'regular-9': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-9" */'./fa-index-free-regular-9'),
    'regular-c': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-c" */'./fa-index-free-regular-c'),
    'regular-e': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-e" */'./fa-index-free-regular-e'),
    'regular-d': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-d" */'./fa-index-free-regular-d'),
    'regular-f': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-f" */'./fa-index-free-regular-f'),
    'regular-a': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-a" */'./fa-index-free-regular-a'),
    'regular-b': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-regular-b" */'./fa-index-free-regular-b'),
}