(function($) {

    $.fn.paragraphCollapse = function() {

        $(this).filter('.paragraph-collapse').each(function(){
            const self = $(this);

            if (self.data('done') !== true) {
                self.data('done', true).addClass('collapsed');
                self.children('.collapse-control').on('click', function(e){
                    e.preventDefault();
                    self.toggleClass('collapsed');
                });
            }
        });

    };
})(jQuery);
