import Cookies from "js-cookie"
import {AbstractEmojiPalette, PaletteClickCallback, PalettePlacement} from './abstract-emoji-palette'
import '../../css/emoji-palette.css'

type Usage = 'comment' | 'reaction'

type Category = 'people' | 'nature' | 'objects' | 'places' | 'symbols'

interface EmojiCategory {
    category: Category
    iconName: string
    label: string
    emojis: string[]
}

export default class EmojiPalette extends AbstractEmojiPalette {

    static readonly EMOJI_CATEGORIES: EmojiCategory[] = [
        {
            category: 'people',
            iconName: 'smile',
            label: '人',
            emojis: [
                'smile', 'laughing', 'blush', 'smiley', 'relaxed', 'smirk', 'heart_eyes', 'kissing_heart',
                'kissing_closed_eyes', 'flushed', 'relieved', 'satisfied', 'grin', 'wink', 'stuck_out_tongue_winking_eye',
                'stuck_out_tongue_closed_eyes', 'grinning', 'kissing', 'kissing_smiling_eyes', 'stuck_out_tongue',
                'sleeping', 'worried', 'frowning', 'anguished', 'open_mouth', 'grimacing', 'confused', 'hushed',
                'expressionless', 'unamused', 'sweat_smile', 'sweat', 'disappointed_relieved', 'weary', 'pensive',
                'disappointed', 'confounded', 'fearful', 'cold_sweat', 'persevere', 'cry', 'sob', 'joy', 'astonished', 'scream',
                'tired_face', 'angry', 'rage', 'triumph', 'sleepy', 'yum', 'mask', 'sunglasses', 'dizzy_face', 'imp',
                'smiling_imp', 'neutral_face', 'no_mouth', 'innocent', 'alien', 'melting_face', 'smiling_face_with_hearts',
                'smiling_face_with_tear', 'zany_face', 'face_with_hand_over_mouth',
                'face_with_open_eyes_and_hand_over_mouth', 'face_with_peeking_eye',
                'shushing_face', 'saluting_face', 'face_with_raised_eyebrow', 'dotted_line_face',
                'face_exhaling', 'face_vomiting', 'hot_face', 'cold_face', 'woozy_face', 'face_with_spiral_eyes',
                'partying_face', 'disguised_face', 'face_with_monocle', 'face_with_diagonal_mouth', 'pleading_face',
                'face_holding_back_tears', 'yawning_face', 'face_with_symbols_on_mouth',
                'lying_face', 'hugs', 'slightly_frowning_face', 'slightly_smiling_face', 'roll_eyes', 'sneezing_face',
                'thinking', 'drooling_face', 'face_with_head_bandage', 'face_with_thermometer', 'frowning_face',
                'upside_down_face', 'money_mouth_face', 'nauseated_face', 'rofl', 'nerd_face', 'zipper_mouth_face', 'star_struck',
                'yellow_heart', 'blue_heart', 'purple_heart', 'yellow_heart', 'blue_heart', 'purple_heart',
                'heart', 'orange_heart', 'green_heart', 'brown_heart', 'white_heart', 'black_heart', 'broken_heart',
                'heartbeat', 'heartpulse', 'two_hearts', 'revolving_hearts', 'cupid', 'sparkling_heart', 'mending_heart',
                'sparkles', 'star', 'star2', 'dizzy', 'boom',
                // 'collision',
                'anger', 'exclamation', 'question',
                'grey_exclamation', 'grey_question', 'heavy_heart_exclamation', 'zzz', 'dash', 'sweat_drops', 'notes',
                'musical_note', 'fire', 'hankey',
                // 'poop', 'shit',
                '+1',
                // 'thumbsup',
                '-1',
                // 'thumbsdown',
                'ok_hand', 'punch', 'facepunch', 'fist', 'v', 'wave',
                'hand', 'raised_hand', 'open_hands', 'point_up', 'point_down', 'point_left', 'point_right', 'raised_hands',
                'pray', 'point_up_2', 'clap', 'muscle', 'metal', 'raised_back_of_hand', 'fu',
                'vulcan_salute', 'raised_hand_with_fingers_splayed', 'call_me_hand', 'crossed_fingers',
                'love_you_gesture', 'fist_left', 'fist_right', 'pinched_fingers', 'pinching_hand',
                'walking_man', 'running_man', 'couple', 'family',
                'two_men_holding_hands', 'two_women_holding_hands', 'dancer', 'dancing_women', 'ok_woman', 'no_good_woman',
                'tipping_hand_woman', 'raising_hand_woman', 'bride_with_veil', 'pouting_woman', 'frowning_woman',
                'bowing_woman', 'bowing_man', 'couplekiss_man_woman', 'couple_with_heart',
                'couple_with_heart_man_man', 'couple_with_heart_woman_man', 'couple_with_heart_woman_woman',
                'couplekiss_man_man', 'couplekiss_woman_woman', 'massage_woman', 'haircut_woman', 'nail_care', 'boy', 'girl', 'woman',
                'man', 'baby', 'older_woman', 'older_man', 'blonde_man', 'man_with_gua_pi_mao',
                'man_with_turban', 'construction_worker_man', 'cop', 'angel', 'princess', 'smiley_cat', 'smile_cat',
                'heart_eyes_cat', 'kissing_cat', 'smirk_cat', 'scream_cat', 'crying_cat_face', 'joy_cat', 'pouting_cat',
                'japanese_ogre', 'japanese_goblin', 'see_no_evil', 'hear_no_evil', 'speak_no_evil', 'guardsman', 'skull',
                'feet', 'lips', 'kiss', 'droplet', 'ear', 'eyes', 'nose', 'tongue', 'love_letter', 'bust_in_silhouette',
                'busts_in_silhouette', 'people_hugging', 'speech_balloon', 'thought_balloon',
                'right_anger_bubble', 'left_speech_bubble', 'basketball_man', 'basketball_woman', 'biking_woman',
                'business_suit_levitating', 'clown_face', 'construction_worker_woman', 'cowboy_hat_face', 'dancing_men',
                'female_detective', 'detective', 'male_detective', 'eye', 'eye_speech_bubble', 'family_man_boy',
                'family_man_boy_boy', 'family_man_girl', 'family_man_girl_boy', 'family_man_girl_girl',
                'family_man_man_boy', 'family_man_man_boy_boy', 'family_man_man_girl', 'family_man_man_girl_boy',
                'family_man_man_girl_girl', 'family_man_woman_boy', 'family_man_woman_boy_boy', 'family_man_woman_girl',
                'family_man_woman_girl_boy', 'family_man_woman_girl_girl', 'family_woman_boy', 'family_woman_boy_boy',
                'family_woman_girl', 'family_woman_girl_boy', 'family_woman_girl_girl', 'family_woman_woman_boy',
                'family_woman_woman_boy_boy', 'family_woman_woman_girl', 'family_woman_woman_girl_boy',
                'family_woman_woman_girl_girl', 'frowning_man', 'golfing_man', 'golfing_woman', 'guardswoman',
                'haircut_man', 'handshake', 'man_artist', 'man_astronaut', 'man_cartwheeling', 'man_cook', 'man_dancing',
                'man_facepalming', 'man_factory_worker', 'man_farmer', 'man_firefighter', 'man_health_worker',
                'man_in_tuxedo', 'man_judge', 'man_juggling', 'man_mechanic', 'man_office_worker', 'man_pilot',
                'man_playing_handball', 'man_playing_water_polo', 'man_scientist', 'man_shrugging', 'man_singer',
                'man_student', 'man_teacher', 'man_technologist', 'massage_man', 'men_wrestling', 'mountain_biking_woman',
                'mrs_claus', 'no_good_man', 'ok_man', 'person_fencing', 'policeman', 'policewoman', 'pouting_man',
                'pregnant_woman', 'prince', 'raising_hand_man', 'robot', 'rowing_man', 'rowing_woman', 'running_woman',
                'selfie', 'skier', 'speaking_head', 'surfing_woman', 'swimming_woman', 'tipping_hand_man', 'walking_woman',
                'weight_lifting_man', 'weight_lifting_woman', 'wind_face', 'woman_artist', 'woman_astronaut',
                'woman_cartwheeling', 'woman_cook', 'woman_facepalming', 'woman_factory_worker', 'woman_farmer',
                'woman_firefighter', 'woman_health_worker', 'woman_judge', 'woman_juggling', 'woman_mechanic',
                'woman_office_worker', 'woman_pilot', 'woman_playing_handball', 'woman_playing_water_polo',
                'woman_scientist', 'woman_shrugging', 'woman_singer', 'woman_student', 'woman_teacher',
                'woman_technologist', 'woman_with_turban', 'women_wrestling', 'writing_hand', 'adult', 'artist',
                'astronaut', 'bald_man', 'bald_woman', 'bearded_person', 'blond_haired_man', 'blond_haired_person',
                'blond_haired_woman', 'bouncing_ball_person', 'breast_feeding', 'cartwheeling', 'child', 'climbing',
                'climbing_man', 'climbing_woman', 'cook', 'couplekiss', 'curly_haired_man', 'curly_haired_woman',
                'deaf_man', 'deaf_person', 'deaf_woman', 'ear_with_hearing_aid', 'elf', 'elf_man', 'elf_woman',
                'exploding_head', 'face_in_clouds', 'facepalm', 'factory_worker', 'fairy', 'fairy_man', 'fairy_woman',
                'farmer', 'firefighter', 'foot', 'frowning_person', 'genie', 'genie_man', 'genie_woman', 'golfing',
                'guard', 'handball_person', 'health_worker', 'heart_on_fire', 'judge', 'juggling_person', 'kneeling_man',
                'kneeling_person', 'kneeling_woman', 'leg', 'lotus_position', 'lotus_position_man', 'lotus_position_woman',
                'mage', 'mage_man', 'mage_woman', 'man_beard', 'man_feeding_baby', 'man_in_manual_wheelchair',
                'man_in_motorized_wheelchair', 'man_with_probing_cane', 'man_with_veil', 'mechanic', 'mermaid',
                'merman', 'merperson', 'mx_claus', 'ninja', 'office_worker', 'ok_person', 'older_adult',
                'palms_up_together', 'people_holding_hands', 'person_bald', 'person_curly_hair', 'person_feeding_baby',
                'person_in_manual_wheelchair', 'person_in_motorized_wheelchair', 'person_in_tuxedo', 'person_red_hair',
                'person_white_hair', 'person_with_probing_cane', 'person_with_turban', 'person_with_veil', 'pilot',
                'pouting_face', 'red_haired_man', 'red_haired_woman', 'sassy_man', 'sauna_man', 'sauna_person',
                'sauna_woman', 'scientist', 'shrug', 'singer', 'standing_man', 'standing_person',
                'standing_woman', 'student', 'superhero', 'superhero_man', 'superhero_woman', 'supervillain',
                'supervillain_man', 'supervillain_woman', 'teacher', 'technologist', 'vampire', 'vampire_man',
                'vampire_woman', 'water_polo', 'weight_lifting', 'white_haired_man', 'white_haired_woman',
                'woman_beard', 'woman_feeding_baby', 'woman_in_manual_wheelchair', 'woman_in_motorized_wheelchair',
                'woman_in_tuxedo', 'woman_with_headscarf', 'woman_with_probing_cane',
                'wrestling', 'zombie', 'zombie_man', 'zombie_woman'
            ]
        },
        {
            category: 'nature',
            iconName: 'sunny',
            label: '自然',
            emojis: [
                'sunny', 'umbrella', 'cloud', 'snowflake', 'snowman', 'zap', 'cyclone', 'foggy', 'ocean', 'cat', 'dog', 'mouse',
                'hamster', 'rabbit', 'wolf', 'frog', 'tiger', 'koala', 'bear', 'pig', 'pig_nose', 'cow', 'boar', 'monkey_face',
                'monkey', 'horse', 'racehorse', 'camel', 'sheep', 'elephant', 'panda_face', 'snake', 'bird', 'baby_chick',
                'hatched_chick', 'hatching_chick', 'chicken', 'penguin', 'turtle', 'bug', 'honeybee', 'ant', 'beetle', 'snail',
                'octopus', 'tropical_fish', 'fish', 'whale', 'whale2', 'dolphin', 'cow2', 'ram', 'rat', 'water_buffalo', 'tiger2',
                'rabbit2', 'dragon', 'goat', 'rooster', 'dog2', 'pig2', 'mouse2', 'ox', 'dragon_face', 'blowfish', 'crocodile',
                'dromedary_camel', 'leopard', 'cat2', 'poodle', 'paw_prints', 'bouquet', 'cherry_blossom', 'tulip',
                'four_leaf_clover', 'rose', 'sunflower', 'hibiscus', 'maple_leaf', 'leaves', 'fallen_leaf', 'herb', 'mushroom',
                'cactus', 'palm_tree', 'evergreen_tree', 'deciduous_tree', 'chestnut', 'seedling', 'blossom', 'ear_of_rice',
                'shell', 'globe_with_meridians', 'sun_with_face', 'full_moon_with_face', 'new_moon_with_face', 'new_moon',
                'waxing_crescent_moon', 'first_quarter_moon', 'waxing_gibbous_moon', 'full_moon', 'waning_gibbous_moon',
                'last_quarter_moon', 'waning_crescent_moon', 'last_quarter_moon_with_face', 'first_quarter_moon_with_face',
                'moon', 'earth_africa', 'earth_americas', 'earth_asia', 'volcano', 'milky_way', 'partly_sunny',
                'bat', 'butterfly', 'chipmunk', 'cloud_with_lightning', 'cloud_with_lightning_and_rain',
                'cloud_with_rain', 'cloud_with_snow', 'comet', 'crab', 'crescent_moon', 'cricket', 'deer', 'dove',
                'duck', 'eagle', 'fog', 'footprints', 'fox_face', 'gorilla', 'lion', 'lizard', 'mountain',
                'mountain_snow', 'owl', 'rhinoceros', 'scorpion', 'shamrock', 'shark', 'shrimp', 'spider', 'spider_web',
                'squid', 'sun_behind_large_cloud', 'sun_behind_rain_cloud', 'sun_behind_small_cloud', 'tornado',
                'turkey', 'unicorn', 'wilted_flower', 'badger', 'beaver', 'bison', 'black_cat', 'cockroach', 'dodo',
                'flamingo', 'fly', 'giraffe', 'guide_dog', 'hedgehog', 'hippopotamus', 'kangaroo', 'lady_beetle',
                'leafy_green', 'llama', 'lobster', 'mammoth', 'microbe', 'mosquito', 'olive', 'orangutan', 'otter',
                'parrot', 'peacock', 'polar_bear', 'raccoon', 'sauropod', 'seal', 'service_dog', 'skunk', 'sloth',
                'swan', 't-rex', 'worm', 'zebra'
            ],
        },
        {
            category: 'objects',
            iconName: 'flags',
            label: '物',
            emojis: [
                'bamboo', 'gift_heart', 'dolls', 'school_satchel', 'mortar_board', 'flags', 'fireworks', 'sparkler',
                'wind_chime', 'rice_scene', 'jack_o_lantern', 'ghost', 'santa', 'christmas_tree', 'gift', 'bell', 'no_bell',
                'tanabata_tree', 'tada', 'confetti_ball', 'balloon', 'crystal_ball', 'cd', 'dvd', 'floppy_disk', 'camera',
                'video_camera', 'movie_camera', 'computer', 'tv', 'iphone', 'phone',
                // 'telephone',
                'telephone_receiver',
                'pager', 'fax', 'minidisc', 'vhs', 'sound', 'speaker', 'mute', 'loudspeaker', 'mega', 'hourglass',
                'hourglass_flowing_sand', 'alarm_clock', 'watch', 'radio', 'satellite', 'loop', 'mag', 'mag_right', 'unlock',
                'lock', 'lock_with_ink_pen', 'closed_lock_with_key', 'key', 'bulb', 'flashlight', 'high_brightness',
                'low_brightness', 'electric_plug', 'battery', 'calling', 'email', 'mailbox', 'postbox', 'bath', 'bathtub',
                'shower', 'toilet', 'wrench', 'nut_and_bolt', 'hammer', 'seat', 'moneybag', 'yen', 'dollar', 'pound', 'euro',
                'credit_card', 'money_with_wings', 'e-mail', 'inbox_tray', 'outbox_tray',
                // 'envelope',
                'incoming_envelope',
                'postal_horn', 'mailbox_closed', 'mailbox_with_mail', 'mailbox_with_no_mail', 'package', 'door', 'smoking',
                'bomb', 'gun', 'hocho', 'pill', 'syringe', 'page_facing_up', 'page_with_curl', 'bookmark_tabs', 'bar_chart',
                'chart_with_upwards_trend', 'chart_with_downwards_trend', 'scroll', 'clipboard', 'calendar', 'date',
                'card_index', 'file_folder', 'open_file_folder', 'scissors', 'pushpin', 'paperclip', 'black_nib', 'pencil2',
                'straight_ruler', 'triangular_ruler', 'closed_book', 'green_book', 'blue_book', 'orange_book', 'notebook',
                'notebook_with_decorative_cover', 'ledger', 'books', 'bookmark', 'name_badge', 'microscope', 'telescope',
                'newspaper', 'football', 'basketball', 'soccer', 'baseball', 'tennis', '8ball', 'rugby_football', 'bowling',
                'golf', 'mountain_biking_man', 'biking_man', 'horse_racing', 'snowboarder', 'swimming_man', 'surfing_man', 'ski', 'spades',
                'hearts', 'clubs', 'diamonds', 'gem', 'ring', 'trophy', 'musical_score', 'musical_keyboard', 'violin',
                'space_invader', 'video_game', 'black_joker', 'flower_playing_cards', 'game_die', 'dart', 'mahjong', 'clapper',
                'memo',
                //'pencil',
                'book', 'art', 'microphone', 'headphones', 'trumpet', 'saxophone', 'guitar', 'athletic_shoe', 'shoe', 'sandal',
                'high_heel', 'lipstick', 'boot', 'shirt',
                // 'tshirt',
                'necktie', 'womans_clothes', 'dress',
                'running_shirt_with_sash', 'jeans', 'kimono', 'bikini', 'ribbon', 'tophat', 'crown', 'womans_hat', 'mans_shoe',
                'closed_umbrella', 'briefcase', 'handbag', 'pouch', 'purse', 'eyeglasses', 'fishing_pole_and_fish', 'coffee',
                'tea', 'sake', 'baby_bottle', 'beer', 'beers', 'cocktail', 'tropical_drink', 'wine_glass', 'fork_and_knife',
                'pizza', 'hamburger', 'fries', 'poultry_leg', 'meat_on_bone', 'spaghetti', 'curry', 'fried_shrimp', 'bento',
                'sushi', 'fish_cake', 'rice_ball', 'rice_cracker', 'rice', 'ramen', 'stew', 'bacon', 'oden', 'dango',
                'fried_egg', // 'egg', Unicodeでeggはfiried_eggに変わってGitHubは二者使い分けてる
                'baguette_bread', 'bread',
                'croissant', 'doughnut', 'custard', 'icecream', 'ice_cream', 'shaved_ice', 'birthday', 'cake', 'cookie', 'chocolate_bar',
                'candy', 'lollipop', 'honey_pot', 'apple', 'green_apple', 'tangerine', 'lemon', 'cherries', 'grapes',
                'watermelon', 'strawberry', 'peach', 'melon', 'banana', 'pear', 'pineapple', 'sweet_potato', 'eggplant',
                'tomato', 'corn',
                'cucumber', 'carrot', 'kiwi_fruit', 'avocado', '1st_place_medal', '2nd_place_medal', '3rd_place_medal',
                'alembic', 'amphora', 'badminton', 'balance_scale', 'ballot_box', 'beach_umbrella', 'bed', 'bellhop_bell',
                'black_flag', 'bow_and_arrow', 'boxing_glove', 'artificial_satellite', 'burrito', 'camera_flash',
                'camping', 'candle', 'canoe', 'card_file_box', 'card_index_dividers', 'chains', 'champagne', 'cheese',
                'clamp', 'clinking_glasses', 'coffin', 'computer_mouse', 'control_knobs', 'couch_and_lamp', 'crayon',
                'crossed_swords', 'dagger', 'dark_sunglasses', 'desktop_computer', 'drum', 'egg', 'envelope_with_arrow',
                'ferry', 'field_hockey', 'file_cabinet', 'film_projector', 'film_strip', 'flight_arrival',
                'flight_departure', 'fountain_pen', 'framed_picture', 'funeral_urn', 'gear', 'goal_net', 'green_salad',
                'hammer_and_pick', 'hammer_and_wrench', 'hole', 'hot_pepper', 'hotdog', 'ice_hockey', 'ice_skate',
                'joystick', 'keyboard', 'kick_scooter', 'label', 'level_slider', 'loud_sound', 'mantelpiece_clock',
                'martial_arts_uniform', 'medal_military', 'medal_sports', 'milk_glass', 'motor_boat', 'motor_scooter',
                'motorcycle', 'motorway', 'newspaper_roll', 'oil_drum', 'old_key', 'open_umbrella', 'paintbrush',
                'pancakes', 'paperclips', 'parasol_on_ground', 'passenger_ship', 'peanuts', 'pen', 'pick', 'ping_pong',
                'plate_with_cutlery', 'popcorn', 'potato', 'prayer_beads', 'printer', 'racing_car', 'rainbow_flag',
                'reminder_ribbon', 'rescue_worker_helmet', 'rosette', 'shallow_pan_of_food', 'shield', 'shopping',
                'shopping_cart', 'skull_and_crossbones', 'sleeping_bed', 'small_airplane', 'snowman_with_snow',
                'spiral_calendar', 'spiral_notepad', 'spoon', 'stopwatch', 'studio_microphone', 'stuffed_flatbread',
                'taco', 'thermometer', 'tickets', 'timer_clock', 'trackball', 'tumbler_glass', 'volleyball',
                'wastebasket', 'white_flag', 'world_map', 'abacus', 'accordion', 'adhesive_bandage', 'anatomical_heart',
                'auto_rickshaw', 'axe', 'bagel', 'ballet_shoes', 'banjo', 'basket', 'bell_pepper', 'beverage_box',
                'billed_cap', 'blueberries', 'bone', 'boomerang', 'bowl_with_spoon', 'brain', 'bricks', 'broccoli',
                'broom', 'bubble_tea', 'bucket', 'butter', 'canned_food', 'carpentry_saw', 'chair', 'chess_pawn',
                'chopsticks', 'coat', 'coconut', 'coin', 'compass', 'cricket_game', 'cup_with_straw', 'cupcake',
                'curling_stone', 'cut_of_meat', 'diving_mask', 'diya_lamp', 'dna', 'dumpling', 'falafel', 'feather',
                'fire_extinguisher', 'firecracker', 'flat_shoe', 'flatbread', 'flying_disc', 'flying_saucer', 'fondue',
                'fortune_cookie', 'garlic', 'gloves', 'goggles', 'headstone', 'hiking_boot', 'hook', 'ice_cube',
                'jigsaw', 'kite', 'knot', 'lab_coat', 'lacrosse', 'ladder', 'long_drum', 'lotion_bottle', 'luggage',
                'lungs', 'magic_wand', 'magnet', 'mango', 'manual_wheelchair', 'mate', 'mechanical_arm',
                'mechanical_leg', 'military_helmet', 'mirror', 'moon_cake', 'motorized_wheelchair',
                'mouse_trap', 'nesting_dolls', 'one_piece_swimsuit', 'onion', 'oyster', 'parachute',
                'petri_dish', 'pickup_truck', 'pie', 'pinata', 'pirate_flag', 'placard', 'plunger',
                'potted_plant', 'pretzel', 'probing_cane', 'razor', 'receipt', 'red_envelope', 'ringed_planet',
                'rock', 'roll_of_paper', 'roller_skate', 'safety_pin', 'safety_vest', 'salt', 'sandwich', 'sari',
                'scarf', 'screwdriver', 'sewing_needle', 'shorts', 'skateboard', 'sled', 'soap', 'socks', 'softball',
                'sponge', 'stethoscope', 'swim_brief', 'takeout_box', 'tamale', 'teapot', 'teddy_bear', 'test_tube',
                'thong_sandal', 'thread', 'toolbox', 'tooth', 'toothbrush', 'waffle', 'window', 'wood', 'yarn', 'yo_yo'
            ]
        },
        {
            category: 'places',
            iconName: 'house',
            label: '場所',
            emojis: [
                'house', 'house_with_garden', 'derelict_house', 'houses', 'school', 'office', 'post_office', 'hospital', 'bank', 'convenience_store',
                'love_hotel', 'hotel', 'wedding', 'church', 'department_store', 'european_post_office', 'city_sunrise',
                'city_sunset', 'mosque', 'hut', 'hindu_temple', 'classical_building', 'stadium', 'synagogue',
                'japanese_castle', 'shinto_shrine', 'european_castle', 'tent', 'factory', 'tokyo_tower',
                'building_construction', 'japan', 'mount_fuji', 'sunrise_over_mountains', 'sunrise', 'stars',
                'statue_of_liberty', 'bridge_at_night', 'cityscape', 'national_park', 'railway_track', 'carousel_horse',
                'rainbow', 'ferris_wheel', 'fountain', 'roller_coaster', 'ship', 'speedboat', 'boat',
                // 'sailboat',
                // 'rowboat_man',
                'anchor', 'rocket', 'airplane', 'helicopter', 'steam_locomotive', 'tram', 'mountain_railway', 'bike',
                'aerial_tramway', 'suspension_railway', 'mountain_cableway', 'tractor', 'blue_car', 'oncoming_automobile',
                'car', 'red_car', 'taxi', 'oncoming_taxi', 'articulated_lorry', 'bus', 'oncoming_bus', 'rotating_light',
                'police_car', 'oncoming_police_car', 'fire_engine', 'ambulance', 'minibus', 'truck', 'train', 'station',
                'train2', 'bullettrain_front', 'bullettrain_side', 'light_rail', 'monorail', 'railway_car', 'trolleybus',
                'ticket', 'fuelpump', 'vertical_traffic_light', 'traffic_light', 'warning', 'construction', 'beginner', 'atm',
                'slot_machine', 'busstop', 'barber', 'hotsprings', 'checkered_flag', 'crossed_flags', 'izakaya_lantern',
                'moyai', 'circus_tent', 'performing_arts', 'round_pushpin', 'triangular_flag_on_post', 'desert_island', 'desert',
                'jp', 'kr', 'cn', 'us',
                'fr', 'es', 'it', 'ru', 'gb',
                // 'uk',
                'de', 'afghanistan', 'aland_islands', 'albania', 'algeria', 'american_samoa', 'andorra',
                'angola', 'anguilla', 'antarctica', 'antigua_barbuda', 'argentina', 'armenia', 'aruba', 'australia',
                'austria', 'azerbaijan', 'bahamas', 'bahrain', 'bangladesh', 'barbados', 'belarus', 'belgium',
                'belize', 'benin', 'bermuda', 'bhutan', 'bolivia', 'bosnia_herzegovina', 'botswana', 'brazil',
                'british_indian_ocean_territory', 'british_virgin_islands', 'brunei', 'bulgaria', 'burkina_faso',
                'burundi', 'cambodia', 'cameroon', 'canada', 'canary_islands', 'cape_verde', 'caribbean_netherlands',
                'cayman_islands', 'central_african_republic', 'chad', 'chile', 'christmas_island', 'cocos_islands',
                'colombia', 'comoros', 'congo_brazzaville', 'congo_kinshasa', 'cook_islands', 'costa_rica',
                'cote_divoire', 'croatia', 'cuba', 'curacao', 'cyprus', 'czech_republic', 'denmark', 'djibouti',
                'dominica', 'dominican_republic', 'ecuador', 'egypt', 'el_salvador', 'equatorial_guinea', 'eritrea',
                'estonia', 'ethiopia', 'eu', 'falkland_islands', 'faroe_islands', 'fiji', 'finland', 'french_guiana',
                'french_polynesia', 'french_southern_territories', 'gabon', 'gambia', 'georgia', 'ghana', 'gibraltar',
                'greece', 'greenland', 'grenada', 'guadeloupe', 'guam', 'guatemala', 'guernsey', 'guinea',
                'guinea_bissau', 'guyana', 'haiti', 'honduras', 'hong_kong', 'hungary', 'iceland', 'india',
                'indonesia', 'iran', 'iraq', 'ireland', 'isle_of_man', 'israel', 'jamaica', 'jersey', 'jordan',
                'kaaba', 'kazakhstan', 'kenya', 'kiribati', 'kosovo', 'kuwait', 'kyrgyzstan', 'laos', 'latvia',
                'lebanon', 'lesotho', 'liberia', 'libya', 'liechtenstein', 'lithuania', 'luxembourg', 'macau',
                'macedonia', 'madagascar', 'malawi', 'malaysia', 'maldives', 'mali', 'malta', 'marshall_islands',
                'martinique', 'mauritania', 'mauritius', 'mayotte', 'mexico', 'micronesia', 'moldova', 'monaco',
                'mongolia', 'montenegro', 'montserrat', 'morocco', 'mozambique', 'myanmar', 'namibia', 'nauru',
                'nepal', 'netherlands', 'new_caledonia', 'new_zealand', 'nicaragua', 'niger', 'nigeria',
                'night_with_stars', 'niue', 'norfolk_island', 'north_korea', 'northern_mariana_islands',
                'norway', 'oman', 'pakistan', 'palau', 'palestinian_territories', 'panama', 'papua_new_guinea',
                'paraguay', 'peru', 'philippines', 'pitcairn_islands', 'poland', 'portugal', 'puerto_rico', 'qatar',
                'reunion', 'romania', 'rwanda', 'samoa', 'san_marino', 'sao_tome_principe', 'saudi_arabia', 'senegal',
                'serbia', 'seychelles', 'sierra_leone', 'singapore', 'sint_maarten', 'slovakia', 'slovenia',
                'solomon_islands', 'somalia', 'south_africa', 'south_georgia_south_sandwich_islands', 'south_sudan',
                'sri_lanka', 'st_barthelemy', 'st_helena', 'st_kitts_nevis', 'st_lucia', 'st_pierre_miquelon',
                'st_vincent_grenadines', 'sudan', 'suriname', 'swaziland', 'sweden', 'switzerland', 'syria',
                'taiwan', 'tajikistan', 'tanzania', 'thailand', 'timor_leste', 'togo', 'tokelau', 'tonga', 'tr',
                'trinidad_tobago', 'tunisia', 'turkmenistan', 'turks_caicos_islands', 'tuvalu', 'uganda', 'ukraine',
                'united_arab_emirates', 'uruguay', 'us_virgin_islands', 'uzbekistan', 'vanuatu', 'vatican_city',
                'venezuela', 'vietnam', 'wallis_futuna', 'western_sahara', 'yemen', 'zambia', 'zimbabwe',
                'ascension_island', 'bouvet_island', 'ceuta_melilla', 'clipperton_island', 'diego_garcia',
                'england', 'heard_mcdonald_islands', 'scotland', 'st_martin', 'svalbard_jan_mayen', 'transgender_flag',
                'tristan_da_cunha', 'united_nations', 'us_outlying_islands', 'wales'
            ]
        },
        {
            category: 'symbols',
            iconName: 'one',
            label: '記号',
            emojis: [
                'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'keycap_ten', '1234', 'hash',
                'asterisk', 'symbols', 'arrow_backward', 'arrow_forward', 'capital_abcd', 'abcd', 'abc',
                'arrow_right', 'arrow_up', 'arrow_left', 'arrow_down',
                'arrow_upper_right', 'arrow_lower_right', 'arrow_lower_left', 'arrow_upper_left',
                'arrow_double_down', 'arrow_double_up', 'arrow_down_small', 'arrow_heading_down', 'arrow_heading_up',
                'leftwards_arrow_with_hook', 'arrow_right_hook', 'left_right_arrow', 'arrow_up_down', 'arrow_up_small',
                'arrows_clockwise', 'arrows_counterclockwise', 'rewind', 'fast_forward',
                'previous_track_button', 'next_track_button', 'play_or_pause_button', 'pause_button', 'record_button',
                'stop_button', 'eject_button', 'information_source', 'ok',
                'twisted_rightwards_arrows', 'repeat', 'repeat_one', 'new', 'top', 'up', 'cool', 'free', 'ng', 'cinema',
                'koko', 'signal_strength', 'u5272', 'u5408', 'u55b6', 'u6307', 'u6708', 'u6709', 'u6e80', 'u7121', 'u7533', 'u7a7a',
                'u7981', 'sa', 'restroom', 'mens', 'elevator', 'womens', 'baby_symbol', 'no_smoking', 'parking', 'wheelchair', 'metro',
                'baggage_claim', 'accept', 'wc', 'potable_water', 'put_litter_in_its_place', 'secret', 'congratulations', 'm',
                'passport_control', 'left_luggage', 'customs', 'ideograph_advantage', 'cl', 'sos', 'id', 'no_entry_sign',
                'underage', 'no_mobile_phones', 'do_not_litter', 'non-potable_water', 'no_bicycles', 'no_pedestrians',
                'children_crossing', 'no_entry', 'eight_spoked_asterisk', 'sparkle', 'eight_pointed_black_star',
                'heart_decoration', 'vs', 'vibration_mode', 'mobile_phone_off', 'chart', 'currency_exchange', 'aries',
                'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpius', 'sagittarius', 'capricorn', 'aquarius',
                'pisces', 'ophiuchus', 'six_pointed_star',
                'atom_symbol', 'latin_cross', 'om', 'menorah', 'orthodox_cross', 'peace_symbol', 'star_and_crescent',
                'place_of_worship', 'star_of_david', 'wheel_of_dharma', 'yin_yang', 'medical_symbol', 'transgender_symbol',
                'negative_squared_cross_mark', 'a', 'b', 'ab', 'o2',
                'diamond_shape_with_a_dot_inside', 'recycle',
                'end', 'back', 'on', 'soon',
                'clock12', 'clock1230', 'clock1', 'clock130', 'clock2', 'clock230',
                'clock3', 'clock330', 'clock4', 'clock430',
                'clock5', 'clock530', 'clock6', 'clock630', 'clock7', 'clock730', 'clock8', 'clock830', 'clock9', 'clock930',
                'clock10', 'clock1030', 'clock11', 'clock1130',
                'heavy_dollar_sign', 'copyright', 'registered', 'tm', 'x', 'heavy_exclamation_mark',
                'bangbang', 'interrobang', 'o', 'heavy_multiplication_x', 'heavy_plus_sign', 'heavy_minus_sign',
                'heavy_division_sign', 'white_flower', '100', 'heavy_check_mark', 'ballot_box_with_check', 'radio_button',
                'link', 'curly_loop', 'wavy_dash', 'part_alternation_mark', 'trident', 'black_small_square',
                'white_small_square', 'black_medium_small_square', 'white_medium_small_square', 'black_medium_square',
                'white_medium_square', 'black_large_square', 'white_large_square',
                'blue_square', 'brown_square', 'green_square', 'orange_square', 'purple_square', 'yellow_square', 'red_square',
                'white_check_mark', 'black_square_button',
                'white_square_button', 'black_circle', 'white_circle', 'red_circle', 'large_blue_circle',
                'brown_circle', 'green_circle', 'orange_circle', 'purple_circle', 'yellow_circle', 'large_blue_diamond',
                'large_orange_diamond', 'small_blue_diamond', 'small_orange_diamond',
                'small_red_triangle', 'small_red_triangle_down',
                'biohazard', 'fleur_de_lis', 'radioactive', 'stop_sign', 'drop_of_blood', 'male_sign', 'female_sign', 'infinity', 'nazar_amulet'
            ],
        }
    ]

    static readonly CLASS_NAME = {
        root: 'emoji-palette-button',
        commentActionModal: 'comment-action-modal',
        palette: 'emoji-palette',
        navTab: 'emoji-category-nav-tab',
        tabSelect: 'emoji-category-tab-select',
        mainSection: 'main-section',
        frequentlyUsed: 'frequently-used',
        emojiCategoryPanels: 'emoji-category-panels',
        emojiPanel: 'emoji-panel',
        modalBackground: 'emoji-palette-background',
        paletteOpen: 'open',
        tabSelectActive: 'active',
        emojiPanelActive: 'active',
    }

    private static instances: Map<string, EmojiPalette> = new Map()

    private tabSelect: Category = 'people'

    private frequentlyUsedEmojiManager: FrequentlyUsedEmojiManager

    private frequentlyUsedEmojis: string[]

    private modalBackground: JQuery

    private navTab: JQuery

    private mainSection: JQuery

    private frequentlyUsedEmojiList: JQuery

    private emojiCategoryPanels: JQuery

    constructor(
        private usage: Usage,
        emojiImageUrl: string,
        palettePlacement: PalettePlacement[],
        onClick: PaletteClickCallback,
        private paletteWidth?: () => number
    ) {
        super(emojiImageUrl, palettePlacement, onClick)
    }

    static getInstance(
        id: string,
        usage: Usage,
        emojiImageUrl: string,
        palettePlacement: PalettePlacement[],
        onClick: PaletteClickCallback,
        paletteWidth?: () => number
    ): EmojiPalette {
        if (this.instances.has(id)) {
            return this.instances.get(id)
        }

        const instance = new EmojiPalette(usage, emojiImageUrl, palettePlacement, onClick, paletteWidth);
        instance.render()
        this.instances.set('id', instance)

        return instance
    }

    override init(): void {
        this.frequentlyUsedEmojiManager = new FrequentlyUsedEmojiManager()
        this.frequentlyUsedEmojis = this.frequentlyUsedEmojiManager.getFrequentlyUsedEmojiList()

        super.init()
    }

    public render() {
        $('body')
            .append(this.renderModalBackground())
            .append(this.renderPalette())
    }

    public addRecentlyUsedEmoji(emoji: string): void {
        this.frequentlyUsedEmojiManager.addRecentlyUsedEmoji(emoji)
        this.setFrequentlyUsedEmoji(this.frequentlyUsedEmojiManager.getFrequentlyUsedEmojiList())
    }

    public setTabSelect(category: Category): void {
        this.tabSelect = category

        this.navTab
            .find('.' + EmojiPalette.CLASS_NAME.tabSelect)
            .removeClass(EmojiPalette.CLASS_NAME.tabSelectActive)

        this.navTab
            .find(`.${EmojiPalette.CLASS_NAME.tabSelect}[data-target-panel="${category}"]`)
            .addClass(EmojiPalette.CLASS_NAME.tabSelectActive)

        this.emojiCategoryPanels
            .find('.' + EmojiPalette.CLASS_NAME.emojiPanel)
            .removeClass(EmojiPalette.CLASS_NAME.emojiPanelActive)

        this.emojiCategoryPanels
            .find(`#emoji-panel-${category}`)
            .addClass(EmojiPalette.CLASS_NAME.emojiPanelActive)
    }

    public setFrequentlyUsedEmoji(emojis: string[]): void {
        this.frequentlyUsedEmojis = emojis

        // すでに実体があるときのみ既存のリストを更新する。
        if (this.frequentlyUsedEmojiList) {
            this.frequentlyUsedEmojiList.empty()
            emojis.forEach(emoji => {
                this.frequentlyUsedEmojiList.append(this.emojiButton(emoji))
            })
        }
    }

    override adjustPalette(target: JQuery): void {
        this.palette.css({
            width: '',
        })

        // テキストエリアの横幅に幅を合わせる（コメントフォーム限定）
        if (this.paletteWidth) {
            const width = this.paletteWidth()
            this.palette.css({width: `${width}px`})
        }

        super.adjustPalette(target)
    }

    private renderModalBackground(): JQuery {
        const background = $(`<div class="${EmojiPalette.CLASS_NAME.modalBackground}"></div>`)
            .addClass('for-' + this.usage)
            .on('click', () => this.close())

        this.modalBackground = background

        return background
    }

    private renderPalette(): JQuery {
        const palette = $(`<div></div>`)
            .addClass(EmojiPalette.CLASS_NAME.palette)
            .addClass(EmojiPalette.CLASS_NAME.commentActionModal)
            .addClass('for-' + this.usage)

        // この中身充填は setIsPaletteOpen(true) の初回まで遅延する
        // .append(this.renderNavTabs())
        // .append(this.renderMainSection())

        if (this.isPaletteOpen) {
            palette.addClass(EmojiPalette.CLASS_NAME.paletteOpen)
        }

        this.palette = palette

        return palette
    }

    private renderNavTabs(): JQuery {
        const container = $(`<ul class="${EmojiPalette.CLASS_NAME.navTab}"></ul>`)
        EmojiPalette.EMOJI_CATEGORIES.forEach(emojiCategory => {
            const tab = $(`<li class="${EmojiPalette.CLASS_NAME.tabSelect}"></li>`)
                .attr('data-target-panel', emojiCategory.category)
                .append(this.twa(emojiCategory.iconName))
                .on('click', e => {
                    this.setTabSelect($(e.currentTarget).data('target-panel'))
                })
            if (this.tabSelect === emojiCategory.category) {
                tab.addClass('active')
            }

            container.append(tab)
        })

        this.navTab = container

        return container
    }

    private renderMainSection(): JQuery {
        const mainSection = $(`<div class="${EmojiPalette.CLASS_NAME.mainSection}"></div>`)
        if (this.usage === 'reaction') {
            mainSection.append(this.renderFrequentlyUsed())
        }
        mainSection.append(this.renderEmojiCategoryPanels())

        this.mainSection = mainSection

        return mainSection
    }

    private renderFrequentlyUsed(): JQuery {
        const emojis = $('<ul></ul>')
        this.frequentlyUsedEmojis.forEach(emoji => {
            emojis.append(this.emojiButton(emoji))
        })

        this.frequentlyUsedEmojiList = emojis

        return $(`<div class="${EmojiPalette.CLASS_NAME.frequentlyUsed}"></div>`)
            .append('<p>よく使われるemoji</p>')
            .append(emojis)
    }

    private renderEmojiCategoryPanels(): JQuery {
        const panels = $(`<div class="${EmojiPalette.CLASS_NAME.emojiCategoryPanels}"></div>`)
        EmojiPalette.EMOJI_CATEGORIES.forEach(emojiCategory => {
            const panel = $(`<div id="emoji-panel-${emojiCategory.category}" class="emoji-panel"></div>`)
            if (this.tabSelect === emojiCategory.category) {
                panel.addClass('active')
            }

            const emojis = $('<ul></ul>')
            emojiCategory.emojis.forEach(emoji => {
                emojis.append(this.emojiButton(emoji))
            })

            panel
                .append(`<p>${emojiCategory.label}</p>`)
                .append(emojis)

            panels.append(panel)
        })

        this.emojiCategoryPanels = panels

        return panels
    }

    openPalette(): void {
        this.target.addClass(EmojiPalette.CLASS_NAME.paletteOpen)
        this.palette.addClass(EmojiPalette.CLASS_NAME.paletteOpen)
        this.modalBackground.addClass(EmojiPalette.CLASS_NAME.paletteOpen)
    }

    closePalette(): void {
        this.target?.removeClass(EmojiPalette.CLASS_NAME.paletteOpen)
        this.palette.removeClass(EmojiPalette.CLASS_NAME.paletteOpen)
        this.modalBackground.removeClass(EmojiPalette.CLASS_NAME.paletteOpen)
    }

    ensurePaletteContent(): void {
        if (this.palette.children().length === 0) {
            this.palette
                .append(this.renderNavTabs())
                .append(this.renderMainSection())
        }
    }

    inExcludedElement(eventTarget: JQuery<EventTarget>): boolean {
        return eventTarget
                .closest('.' + EmojiPalette.CLASS_NAME.root)
                .is(this.target)
            || eventTarget
                .closest('.' + EmojiPalette.CLASS_NAME.palette)
                .is(this.palette);
    }
}

class FrequentlyUsedEmojiManager {

    static readonly DEFAULT_FREQUENTLY_USE = [
        'smile', '+1', 'heart'
    ]

    static readonly RECENTLY_USED_EMOJI_COOKIE_NAME = '_zw_recently_used_emoji'

    static readonly RECENTLY_USED_EMOJI_LIMIT = 10

    static readonly RECENTLY_USED_EMOJI_COOKIE_LIFETIME = 365

    public addRecentlyUsedEmoji(emoji: string): void {
        if (FrequentlyUsedEmojiManager.DEFAULT_FREQUENTLY_USE.includes(emoji)) {
            return
        }

        const oldLog = Cookies.get(FrequentlyUsedEmojiManager.RECENTLY_USED_EMOJI_COOKIE_NAME)
        const oldRecentlyUsedEmoji = oldLog ? JSON.parse(oldLog) : []

        let newRecentlyUsedEmoji

        const idx = oldRecentlyUsedEmoji.indexOf(emoji)
        if (idx === -1) {
            newRecentlyUsedEmoji = oldRecentlyUsedEmoji
                .slice(0, FrequentlyUsedEmojiManager.RECENTLY_USED_EMOJI_LIMIT - 2)
            newRecentlyUsedEmoji.unshift(emoji)
        } else {
            // 対象のemojiが最近使ったemojiリストの中に存在していたら一番手前に持ってくる
            oldRecentlyUsedEmoji.splice(idx, 1)
            oldRecentlyUsedEmoji.unshift(emoji)
            newRecentlyUsedEmoji = oldRecentlyUsedEmoji
        }

        Cookies.set(
            FrequentlyUsedEmojiManager.RECENTLY_USED_EMOJI_COOKIE_NAME,
            JSON.stringify(newRecentlyUsedEmoji),
            {
                expires: FrequentlyUsedEmojiManager.RECENTLY_USED_EMOJI_COOKIE_LIFETIME
            }
        )
    }

    public getFrequentlyUsedEmojiList(): string[] {
        const rawRecentlyUsed = Cookies.get(FrequentlyUsedEmojiManager.RECENTLY_USED_EMOJI_COOKIE_NAME)
        const recentlyUsed = rawRecentlyUsed ? JSON.parse(rawRecentlyUsed) : []

        return Array.from(new Set([
            ...FrequentlyUsedEmojiManager.DEFAULT_FREQUENTLY_USE, ...recentlyUsed
        ]))
    }
}
