/**
 * Created by yoshikazu on 2016/08/09.
 */
(function($){

    $.fn.subtreeLoader = function(options) {
        options = $.extend({
            style: null
        }, options);

        const $itemsContainer = $(this);

        const handler = function (e) {
            e.preventDefault();

            const a = $(this);
            const spin = a.find('.glyphicon-refresh').addClass('fa-spin');

            $itemsContainer.trigger('pjax:start');

            $.ajax({
                url: a.data('subtree-url'),
                method: 'post',
                data: {style: options.style},
                dataType: 'html'
            }).done(function (data) {
                const content = $($.parseHTML(data)).find('.discussion-main').children();
                if (content.length > 0) {
                    content.addClass('last-posted-comment-effect');
                    a.parent().replaceWith(content);

                    // 初期化
                    content.find('.emoji-text').emojify();
                    content.find('.paragraph-collapse').paragraphCollapse();

                    if (!!$.fn.expandCommentRef) {
                        content.find('.comment-detail .header .comment-parent-link').expandCommentRef({headerAnchor: true});
                        content.find('.comment .content .body .comment-parent-link').expandCommentRef();
                    }
                    Prism.highlightAll();

                    $itemsContainer.trigger('pjax:success');
                }
                spin.removeClass('fa-spin');
            });
        };

        const registerHandler = function () {
            $itemsContainer.find('.more-child-comments > a').off('.subtree').on('click.subtree', handler);
        };
        registerHandler();

        $itemsContainer.on("pjax:success", function(/*event, xhr, textStatus, errorThrown, options*/) {
            registerHandler();
        });
    };

})(jQuery);
