(function($) {
    $.fn.closeHintArea = function(options) {
        options = $.extend({
            url: '#'
        }, options);

        $(this).on('click', function () {
            const button = $(this);

            $.ajax({
                url: options.url,
                type: 'POST',
                data: {
                    hint: button.data('hint')
                },
                dataType: 'json'
            }).done(function(data){
                if (data && data.hasOwnProperty('status') && data.status === 'error' && data.hasOwnProperty('message')) {
                    alert('メッセージを既読にできませんでした。【' + data.message + '】\n状態を確認するには、ページを再読み込みしてください。');
                } else {
                    $(button.data('target')).fadeOut();
                }
            }).fail(function(){
                alert('通信エラーのため、メッセージを既読にできませんでした。\n状態を確認するには、ページを再読み込みしてください。');
            });
            return false;
        });
    };
})(jQuery);
