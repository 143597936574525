bootbox.setDefaults({
    locale: "ja",
    className: "app-modal",
})

yii.confirm = function(message: string, ok?: () => any, cancel?: () => any) {
    bootbox.dialog({
        message: message,
        title: "<span class='glyphicon glyphicon-alert'></span> 確認",
        buttons: {
            success: {
                label: "はい",
                className: "btn-primary",
                callback: ok
            },
            danger: {
                label: "いいえ",
                className: "btn-default",
                callback: cancel
            }
        }
    })
}