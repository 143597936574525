(function($) {

    let uid = 0;

    $.fn.mediaModal = function(options) {
        options = $.extend({
            contentSelector: '.content .body',
            profileSelector: '.content .comment-icon-container, .owner-icon-container',
            mediaLinkSelector: '.media-modal',
            mentionSelector: '.mention',
            embedMode: false
        }, options);

        const container = this;

        function adjustModalPosition() {
            const $body = $('body');
            // 埋め込みスタイルでのみ発動
            if ($body.hasClass('embed-theme-light') || $body.hasClass('embed-theme-dark')) {
                // Bootstrap がモーダルの中央寄せに align-items: center を使うように変わったので
                // 上記の座標調整が妥当でも、(IFRAMEが縦長であればあるほど)表示位置が狂う。領域の原点に戻す。
                this._$modal.css('align-items', 'baseline');

                const distanceModalScreenTopToLinkPos = this._$element.offset().top - this._$modal.offset().top;
                const heightModalScreen = this._$modal.height();
                this._$modal.find('.modal-dialog').css({
                    top: Math.min(
                        Math.max(distanceModalScreenTopToLinkPos - 280, 0),
                        Math.max(heightModalScreen - 610, 0)
                    )
                });
            }
        }

        function gallery() {
            container.find(options.contentSelector).each(function() {
                const content = jQuery(this);
                if (content.find(options.mediaLinkSelector).data('media-modal-init') === true) {
                    return;
                }
                uid++;

                const mediaLinks = content.find(options.mediaLinkSelector);
                const mediaCount = mediaLinks.length;
                mediaLinks.each(function(i){
                    const medium = jQuery(this);
                    if (mediaCount > 1) {
                        medium.attr('data-title', '画像 ' + (i+1) + ' / ' + mediaCount);
                        medium.attr('data-gallery', 'gallery-' + uid);
                    }

                    medium.attr('data-footer', '<a href="' + encodeURI(medium.attr('href')) + '" target="_blank">画像を別ウィンドウで開く</a>');
                    medium.on('click', function(event){
                        event.preventDefault();
                        $(this).ekkoLightbox({
                            onShow: adjustModalPosition
                        });
                    });
                });

                content.find(options.mediaLinkSelector).data('media-modal-init', true);
            });
        }

        // TODO 任意ダイアログは Lightbox を使わない実装へ持っていきたい

        function profile() {
            container.find(options.profileSelector).find(options.mediaLinkSelector).each(function(){
                const medium = jQuery(this);
                if (medium.data('media-modal-init') === true) {
                    return;
                }
                medium.on('click', function(event){
                    event.preventDefault();
                    $(this).ekkoLightbox({
                        noResize: true,
                        modalClass: 'lb-profile',
                        onShow: adjustModalPosition,
                        onShown: function() {
                            this._$modal.find('[data-toggle=\"tooltip\"]').tooltip();
                        }
                    });
                }).data('media-modal-init', true);
            });
        }

        function mention() {
            container.find(options.contentSelector).find(options.mentionSelector).each(function(){
                const medium = jQuery(this);
                if (medium.data('media-modal-init') === true) {
                    return;
                }
                medium.on('click', function(e){
                    e.preventDefault();
                    $(this).tooltip('hide').ekkoLightbox({
                        noResize: true,
                        modalClass: 'lb-mention',
                        onShow: adjustModalPosition,
                        onShown: function() {
                            this._$modal.find('[data-toggle=\"tooltip\"]').tooltip();
                        }
                    });
                });
                medium.data('media-modal-init', true);
            });
        }

        function init() {
            profile();
            mention();
            gallery();
        }

        let delaying = false;
        const observer = new MutationObserver(function() {
            if (delaying) {
                return;
            }
            delaying = true;
            setTimeout(function() {
                init();
                setTimeout(function(){
                    delaying = false;
                }, 10);
            }, 500);
        });
        container.each(function() {
            observer.observe(this, {childList: true, subtree: true});
        });

        init();
    };
})(jQuery);
