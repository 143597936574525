import EmojiPalette from "./emoji-palette";
import SimpleEmojiPalette from "./simple-emoji-palette";

(function ($) {
    'use strict';

    $.fn.emojiReaction = function(options) {

        const settings = $.extend({
            renderUserList: false,
            reactionOnUrl: '',
            reactionOffUrl: '',
            selfReactionUrl: '',
            defaultUserIconUrl: '',
            userProfileUrl: '',
            pjaxSectionSelector: '#comment-list-pjax',
            commentSelector: '.comment',
            contentSelector: '.content',
            childCommentsSelector: '.child-comments',
            actionMenuSelector: '.comment-action-menu',
            paletteButtonSelector: '.emoji-palette-button',
            reactionContainerSelector: '.comment-reactions-container',
            reactionButtonsSelector: '.comment-reactions-container .reaction-buttons',
            commentReactionDataSelector: '.comment-reactions-container .reaction-data',
            reactionUserListSelector: '.reaction-user-list-container .reaction-user-list',
            reactionUserListDataSelector: '.reaction-user-list-container .reaction-user-list-data',
            emojiImageUrl: '',
            selfReaction: [],
            reactionPaletteType: 'normal',
            simpleEmojiPaletteSelect: [],
            embedStyle: 'default',
        }, options);

        const _twemoji_map = window._emoji.emojiname2codepoint;

        const root = this;

        const palette = getPalette(settings.reactionPaletteType);

        const inReactionState = new Map();
        window.addEventListener('pageshow', function (e) {
            // 戻るボタンで戻ってきたときにロックを外すため
            if (e.persisted) {
                inReactionState.clear();
            }
        });

        let comments = root.find(settings.commentSelector);

        function getPalette(paletteType) {
            const onClick = function (emoji, target, palette, event) {
                const targetComment = target.closest(settings.commentSelector);
                const commentNumber = getCommentNumber(targetComment);
                const targetButton = targetComment
                    .children(settings.contentSelector)
                    .find(settings.reactionButtonsSelector)
                    .find(`.reaction-button[data-emoji="${emoji}"]`);

                if (targetButton.length > 0) {
                    if (targetButton.hasClass('self-reaction')) {
                        reactOff(commentNumber, emoji, targetButton, palette);
                    } else {
                        reactOn(commentNumber, emoji, targetButton, palette);
                    }
                } else {
                    reactOn(commentNumber, emoji, null, palette);
                }
            };

            if (paletteType === 'simple') {
                return SimpleEmojiPalette.getInstance(
                    settings.simpleEmojiPaletteSelect,
                    settings.emojiImageUrl, ['bottom', 'right'], onClick
                );
            }

            return EmojiPalette.getInstance(
                'for-reaction',
                'reaction',
                settings.emojiImageUrl,
                ['bottom', 'right'], onClick);
        }

        function init() {
            inReactionState.clear();

            renderReactionPaletteButton();

            if (settings.renderUserList) {
                const list = [];
                $(settings.reactionUserListDataSelector).each(function (i, e) {
                    list.push({
                        emoji: $(e).data('emoji'),
                        guestUserCount: $(e).data('guest-user-count'),
                        users: $(e).data('users') || [],
                    });
                });
                sortByEmojiReactionOrder(comments.first(), list);

                renderReactionUserList(list);
            }

            comments.each(function(i, e) {
                if (isInitialized($(e))) {
                    return;
                }

                $(e).addClass('emoji-reaction-enabled');

                const content = $(e).children(settings.contentSelector);
                content.find(settings.commentReactionDataSelector).each(function (ii, ee) {
                    const commentNumber = getCommentNumber($(e));
                    const emoji = $(ee).data('emoji');
                    const count = $(ee).data('count');

                    const selfReacted = settings.selfReaction.some(function (selfReactionElm) {
                        return selfReactionElm.commentNumber === commentNumber && selfReactionElm.emoji === emoji;
                    });
                    renderEachReaction(commentNumber, emoji, count, selfReacted);

                    // 事前に高さを確保しておくためのクラスをremoveしておく（リアクションが2列になると高さが足りなくなるため）
                    $('#comment-' + commentNumber)
                        .children(settings.contentSelector)
                        .find(settings.reactionContainerSelector)
                        .removeClass('prepareHeight');
                });

                $(e).attr('data-initialized', 1);
            });

            // コメント修正フォームが開かれたらリアクションできないようにする
            comments.each(function () {
                $(this)
                    .off('modify-content-on')
                    .on('modify-content-on', function () {
                        $(this)
                            .children(settings.contentSelector)
                            .find(settings.reactionButtonsSelector)
                            .find('button.reaction-button')
                            .attr('disabled', 'disabled')
                        ;
                    })
                    .off('modify-content-off')
                    .on('modify-content-off', function () {
                        $(this)
                            .children(settings.contentSelector)
                            .find(settings.reactionButtonsSelector)
                            .find('button.reaction-button')
                            .removeAttr('disabled')
                        ;
                    })
            })
        }

        function isInitialized(comment) {
            return Number(comment.data('initialized')) === 1;
        }

        function getCommentNumber($comment) {
            const id = $comment.attr('id');
            const capture = id.match(/^comment-(\d+)$/);
            return Number(capture[1]);
        }

        function renderReactionUserList(list) {
            const targetComment = comments.first();
            if (isInitialized(targetComment)) {
                return;
            }

            const container = targetComment.children(settings.contentSelector).find(settings.reactionUserListSelector);
            const commentReactionGroupsContainer = $('<ul class="comment-reaction-groups"></ul>');

            list.forEach(function (reaction) {
                const emojiImage = $('<div class="emoji-image"></div>').append(_twa(reaction.emoji, null));
                const userList = $('<ul class="user-list"></ul>');

                reaction.users.forEach(function (user) {
                    const iconUrl = user.smallIconUrl || settings.defaultUserIconUrl;
                    const id = user.alias || user.trip;
                    const url = settings.userProfileUrl + '&tripCode=' + user.trip;
                    const title = id + ' のプロフィール';
                    userList.append('<li><a class="media-modal" data-type="url" data-title="' + title +' " href="' + url + '">' +
                        '<img src="' + iconUrl + '" alt=""><span class="user-id">' + id + '</span></a></li>');
                });

                if (reaction.users.length > 0) {
                    userList.append('<li>...</li>');
                }

                const guestUserCount = reaction.guestUserCount > 0 ? $('<li>ゲストユーザー ' + reaction.guestUserCount + ' 人</li>') : '';
                userList.append(guestUserCount);

                const eachEmojiReactionContainer = $('<li class="each-emoji-reaction"></li>')
                    .append(emojiImage)
                    .append(userList);

                commentReactionGroupsContainer.append(eachEmojiReactionContainer);

            });

            if (list.length > 0) {
                container.append('<p>このコメントに反応した人</p>');
            }
            container.append(commentReactionGroupsContainer);
        }

        function sortByEmojiReactionOrder($targetComment, emojiReactionList) {
            const container = $targetComment.children(settings.contentSelector).find(settings.reactionButtonsSelector);

            const order = {};
            container.find('button').each(function (i, e) {
                const button = $(e);
                order[button.data('emoji')] = button.index();
            });
            
            emojiReactionList.sort(function (a, b) {
                if (order[a.emoji] < order[b.emoji]) {
                    return -1;
                }

                if (order[a.emoji] > order[b.emoji]) {
                    return 1;
                }

                return 0;
            });
        }

        function renderEachReaction(commentNumber, emoji, count, isSelfReaction) {
            if (count < 1) {
                return;
            }

            const targetComment = $('#comment-' + commentNumber);
            const container = targetComment.children(settings.contentSelector).find(settings.reactionButtonsSelector);

            container.append(reactionButton(commentNumber, emoji, count, isSelfReaction));
        }

        function reactionButton(commentNumber, emoji, count, isSelfReaction) {
            const button = $('<button class="reaction-button" type="button" title="' + emoji + '" data-emoji="' + emoji + '"><span class="reaction-count">' + count + '</span></button>');
            if (isSelfReaction) {
                button.addClass('self-reaction');
            }

            button.prepend(_twa(emoji, null));

            button.on('click', function (e) {
                e.preventDefault();

                var targetButton = $(e.currentTarget);

                if (targetButton.hasClass('self-reaction')) {
                    reactOff(commentNumber, emoji, targetButton);
                } else {
                    reactOn(commentNumber, emoji, targetButton);
                }
            });

            return button;
        }

        function reactOn(commentNumber, emoji, button, palette) {
            const inReactionStateKey = getInReactionStateKey(commentNumber, emoji);
            if (inReactionState.get(inReactionStateKey)) {
                return;
            }

            inReactionState.set(inReactionStateKey, true);

            const $comment = $('#comment-' + commentNumber);
            $.post(settings.reactionOnUrl, {
                commentNumber: commentNumber,
                emoji: emoji,
            }).done(function (data) {
                if (button) {
                    const reactionCount = button.find('.reaction-count');
                    const count = Number(reactionCount.text());

                    reactionCount.text(isNaN(count) ? 1 : count + 1);
                    button.addClass('self-reaction');
                } else {
                    button = reactionButton(commentNumber, emoji, 1, true);

                    $comment
                        .children(settings.contentSelector)
                        .find(settings.reactionButtonsSelector)
                        .append(button);
                }

                // emoji投稿後メニュー表示しっぱなしにしたくないので消す
                $comment
                    .children(settings.contentSelector)
                    .find(settings.actionMenuSelector)
                    .removeClass('active');

                if (palette) {
                    if (palette.addRecentlyUsedEmoji) {
                        palette.addRecentlyUsedEmoji(emoji);
                    }
                    palette.close();
                }

            }).fail(function (jqXHR) {
                const msg = jqXHR.responseJSON && jqXHR.responseJSON.message
                                ? jqXHR.responseJSON.message
                                : 'エラーが発生しました。';
                errorMessage(msg, commentNumber, button);
            }).always(function () {
                inReactionState.set(inReactionStateKey, false);
            });
        }

        function reactOff(commentNumber, emoji, button, palette) {
            const inReactionStateKey = getInReactionStateKey(commentNumber, emoji);
            if (inReactionState.get(inReactionStateKey)) {
                return;
            }

            inReactionState.set(inReactionStateKey, true);

            $.post(settings.reactionOffUrl, {
                commentNumber: commentNumber,
                emoji: emoji,
            }).done(function (data) {
                const reactionCount = button.find('.reaction-count');
                const rawCount = Number(reactionCount.text());
                const count = isNaN(rawCount) || rawCount < 1 ? 0 : rawCount - 1;

                if (count < 1) {
                    button.remove();
                } else {
                    reactionCount.text(count);
                    button.removeClass('self-reaction');
                }

                // emoji投稿後メニュー表示しっぱなしにしたくないので消す
                $('#comment-' + commentNumber)
                    .children(settings.contentSelector)
                    .find(settings.actionMenuSelector)
                    .removeClass('active');

                if (palette) {
                    palette.close();
                }

            }).fail(function (jqXHR) {
                const msg = jqXHR.responseJSON && jqXHR.responseJSON.message
                    ? jqXHR.responseJSON.message
                    : 'エラーが発生しました。';
                errorMessage(msg, commentNumber, button);
            }).always(function () {
                inReactionState.set(inReactionStateKey, false);
            });
        }

        function getInReactionStateKey(commentNumber, emoji) {
            return commentNumber + '-' + emoji;
        }

        function errorMessage(msg, commentNumber, button) {
            let target = button;
            if (!target) {
                const menu = $(`#comment-${commentNumber}`)
                    .children(settings.contentSelector)
                    .find(settings.actionMenuSelector);
                target = menu.find(settings.paletteButtonSelector);

                showTooltipForPalette(menu, target, msg);
            } else {
                showTooltip(target, msg);
            }
        }

        function showTooltipForPalette(menu, target, msg) {
            // エラーメッセージは出ているのにメニューごと消えているという状態を避ける
            // また、メニューがレンダリングされないとツールチップ表示に必要な
            // 正確な高さやpositionが取得できない
            menu.addClass('active');

            showTooltip(target, msg);

            palette.close();
        }

        function showTooltip(target, msg) {
            let tooltip = $('.tooltip-for-menu');
            if (tooltip.length < 1) {
                tooltip = $('<span class="tooltip-for-menu"></span>');
                $('body').append(tooltip);
            }

            tooltip.text(msg);

            const offset = target.offset();
            const targetTop = offset.top;
            const targetLeft = offset.left;
            const targetWidth = target.outerWidth();
            const tooltipHeight = tooltip.outerHeight();
            const tooltipWidth = tooltip.outerWidth();

            const arrowHeight = 5

            const left = Math.max(targetLeft + targetWidth - tooltipWidth, 0)

            // top は ツールチップの高さ + 矢印の高さ分だけ メニューのミュートボタンの上の位置に、
            // left はボタンの右端とツールチップの右端が一致するように
            tooltip
                .css('top', targetTop - (arrowHeight + tooltipHeight))
                .css('left', left)
                .fadeIn('fast');

            // zawazawaスタイルのzcommentで一番上のコメントにたいしてツールチップを出すと
            // 上が途切れる場合がある。将来的に埋め込みでもemojiを使うようになったときのために。
            if (tooltip.offset().top < 0) {
                const targetHeight = target.outerHeight();
                tooltip
                    .css({
                        top: targetTop + targetHeight + arrowHeight,
                    })
                    .addClass('up-arrow');
            } else {
                tooltip.removeClass('up-arrow');
            }
        }

        /**
         * Twemoji Awesome による顔文字
         * @param {String} name
         * @param {String} addClass
         * @returns {jQuery}
         */
        function _twa(name, addClass) {
            const codenum = _twemoji_map[name];
            return $('<span/>').attr('class', 'twa' + (addClass ? ' ' + addClass : '')).css({
                'background-image': "url('" + settings.emojiImageUrl + codenum + ".svg')"
            });
        }

        function renderReactionPaletteButton() {
            comments.each(function (i, e) {
                if (isInitialized($(e))) {
                    return;
                }

                const menu = $(e).children(settings.contentSelector).find(settings.actionMenuSelector);

                const paletteButtonRoot = $('<div class="comment-action-button emoji-palette-button"></div>');
                const paletteButton = $('<button></button>')
                    .append('<i class="fa-regular fa-face-smile-plus"></i>')
                    .on('click', function () {
                        $(document).trigger('action-menu-button-click', {
                            actionType: 'reaction',
                            origin: this
                        });
                        palette.toggle(paletteButtonRoot);
                    })
                ;
                if (settings.embedStyle !== 'wikiwiki') {
                    paletteButton.append('<span class="action-description">リアクション</span>');
                }
                paletteButtonRoot.append(paletteButton)

                menu.prepend(paletteButtonRoot);
            });
        }

        init();
        this.find(settings.pjaxSectionSelector).on('pjax:success', async function () {
            comments = root.find(settings.commentSelector);

            const commentEmojiPair = [];

            comments.each(function () {
                const comment = $(this)
                comment.find(settings.commentReactionDataSelector).each(function () {
                    commentEmojiPair.push({
                        number: getCommentNumber(comment),
                        emoji: $(this).data('emoji'),
                    });
                });
            });

            $.post(settings.selfReactionUrl, {
                commentEmojiPair: JSON.stringify(commentEmojiPair),
            }).done(function (data) {
                settings.selfReaction = data;
                init();
            });
        });

        $(document).on('click', () => $('.tooltip-for-menu').hide());
    };
})(jQuery);
