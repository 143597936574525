(function($) {

    $.fn.modificationForm = function(options) {
        options = $.extend({
            controlAnonymous: null,
            controlSubmit: {
                fieldSelector: null,
                submitSelector: null
            },
            controlComment: {
                checkboxSelector: null,
                commentSelector: null
            }
        }, options);
        const $form = $(this);
        const controlAnonymous = options.controlAnonymous;

        // 匿名チェックボックス
        if (controlAnonymous) {
            const $nickname = $form.find('[name="modification[nickname]"]');
            $form.find('[name="modification[anonymous]"]').on('change', function () {
                const checked = $(this).prop("checked");
                const userThumbnail = $form.find('.form-user-thumbnail');
                const anonymousThumbnail = $form.find('.form-anonymous-thumbnail');
                if (checked) {
                    userThumbnail.hide();
                    anonymousThumbnail.show();
                    if (typeof $nickname.data('modified') === 'undefined') {
                        $nickname.data('modified', "");
                    }
                    $nickname.prop("disabled", false).val($nickname.data('modified'));
                } else {
                    anonymousThumbnail.hide();
                    userThumbnail.show();
                    $nickname.data("modified", $nickname.val());
                    $nickname.prop("disabled", true).val($nickname.data("username"));
                }
            });
            if ($nickname.data('username')) {
                $nickname.prop("disabled", true).val($nickname.data("username"));
            }
        }

        // 編集者コメントが書き換わってない場合は、新しい履歴を切ろうとしたときクリアされる。
        // やっぱり新しい履歴を切らない、とチェックをオフにしたとき、無操作なら適切に元に戻す。
        const controlComment = options.controlComment;
        let comment = null;
        let checkbox = null;
        if (controlComment && controlComment.checkboxSelector && controlComment.commentSelector) {

            comment = $form.find(controlComment.commentSelector);
            checkbox = $form.find(controlComment.checkboxSelector);

            if (comment.length === 1 && checkbox.length === 1) {

                comment.data('initial-value', comment.val());

                checkbox.on('change', function(){
                    const checkbox = $(this);
                    if (checkbox.is(':checked')) {
                        if (comment.val() === comment.data('initial-value')) {
                            comment.val('');
                        }
                    } else {
                        if (comment.val() === '') {
                            comment.val(comment.data('initial-value'));
                        }
                    }
                });
            }
        }

        // 有意な変更がない限り送信ボタンを無効化
        const controlSubmit = options.controlSubmit;
        if (controlSubmit && controlSubmit.fieldSelector && controlSubmit.submitSelector) {

            const fieldList = $form.find(controlSubmit.fieldSelector);
            const submit = $form.find(controlSubmit.submitSelector);

            function refreshState() {
                let unlock = false;
                fieldList.each(function(){
                    const field = $(this);
                    if (field.attr('type') === 'checkbox') {
                        if (field.data('initial-value') !== field.is(':checked')) {
                            unlock = true;
                        }
                    } else {
                        if (field.data('initial-value') !== field.val()) {
                            unlock = true;
                        }
                    }
                });

                // 同一人物による同一履歴の上書きでは、「編集者コメント」の変更も変更ありと認識してロック解除
                if (unlock !== true &&
                    checkbox !== null &&
                    checkbox.length === 1 &&
                    checkbox.is(':checked') === false &&
                    comment !== null &&
                    comment.length === 1 &&
                    comment.data('initial-value') !== comment.val()
                ) {
                    unlock = true
                }

                if (unlock) {
                    submit.prop('disabled',false)
                } else {
                    submit.prop('disabled',true)
                }
            }

            fieldList.each(function(){
                const field = $(this);

                if (field.attr('type') === 'checkbox') {
                    field.data('initial-value', field.is(':checked'))
                } else {
                    field.data('initial-value', field.val());
                }
                field.on('change keyup', refreshState);
            });

            if (comment && checkbox) {
                comment.on('change keyup', refreshState);
                checkbox.on('change', refreshState);
            }

            refreshState();
        }

    };

})(jQuery);
