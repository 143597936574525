jQuery.fn.extend({
    "confirmBeforeSubmit": function(this: JQuery, options: Record<string, any>) {
        options = $.extend({
            message: "本当に実行しますか。",
        }, options)

        this.each(() => {
            setupButtonHandler($(this), options.message);
        })
    }
})

function setupButtonHandler($button: JQuery<HTMLElement>, message: string) {
    const $form = $button.closest('form')
    $form.data('confirmed', false)
    $button.on('click', () => {
        $form.data('confirmed', false)
    })

    $form.on('beforeSubmit', () => {
        if (!$form.data('confirmed') && !$form.data('confirming')) {
            $form.data('confirming', true)
            const data = $form.data('yiiActiveForm')
            yii.confirm(message, () => {
                setTimeout(() => {
                    $form.data('confirmed', true)
                    data.validated = true
                    $form.submit()
                }, 1)
            }, () => {
                $form.data('confirming', false)
            })
            //data.validated = false;
            return false
        } else {
            return true
        }
    })
}
