export type PaletteClickCallback = (emoji: string, target: JQuery, palette: AbstractEmojiPalette, e: JQuery.ClickEvent) => void

export type PalettePlacement = 'top' | 'bottom' | 'left' | 'right'

export abstract class AbstractEmojiPalette {

    protected isPaletteOpen: boolean = false

    protected palette: JQuery

    protected target?: JQuery = null

    protected constructor(
        private emojiImageUrl: string,
        private palettePlacement: PalettePlacement[],
        private onClick: PaletteClickCallback
    ) {
        this.init()
    }

    protected init(): void {
        document.addEventListener('click', this.closePaletteByClickDisplay.bind(this))
    }

    private closePaletteByClickDisplay(e: Event): void {
        if (this.inExcludedElement($(e.target))) {
            return
        }

        this.close()
    }

    abstract inExcludedElement(eventTarget: JQuery<EventTarget>): boolean

    abstract ensurePaletteContent(): void

    abstract openPalette(): void

    abstract closePalette(): void

    public toggle(target: JQuery): void {
        if (this.isPaletteOpen) {
            this.close()
            if (!this.target.is(target)) {
                // パレットを開いた状態で別のコメントに対するリアクションボタンが押されたらそちらのパレットを開く
                this.open(target)
            }
        } else {
            this.open(target)
        }
    }

    public open(target: JQuery): void {
        this.target = target
        this.isPaletteOpen = true

        this.ensurePaletteContent()

        this.openPalette()

        this.adjustPalette(target)
    }

    public close(): void {
        if (!this.isPaletteOpen) {
            return
        }

        this.isPaletteOpen = false

        this.closePalette()
    }

    protected emojiButton(emoji: string): JQuery {
        return $('<li></li>')
            .append(this.twa(emoji))
            .on('click', (e: JQuery.ClickEvent) => {
                this.onClick(emoji, this.target, this, e)
            })
    }

    protected twa(name: string): JQuery {
        const codeNum = _emoji.emojiname2codepoint[name]
        return $('<span class="twa"></span>')
            .attr({'title': name })
            .css({'background-image': `url(${this.emojiImageUrl + codeNum}.svg)`})
    }

    protected adjustPalette(target: JQuery): void {
        // コメントフォームの位置によって適切な値が変わるので、
        // 前回 adjustPalette した結果をクリア
        this.palette.css({
            top: '',
            left: '',
        })

        this.adjustPosition(target)
    }

    protected adjustPosition(target: JQuery): void {
        // 表示位置をボタンの位置に合わせる
        const offset = target.offset()
        const placement = {}
        const top = offset.top - this.palette.outerHeight() - 2
        const bottom = offset.top + target.outerHeight() + 2

        placement['top'] = this.palettePlacement.includes('top') && top > 0
            ? top
            : bottom

        placement['left'] = this.palettePlacement.includes('left')
            ? offset.left
            : offset.left + target.outerWidth() - this.palette.outerWidth()

        this.palette.css(placement)
    }

}
