(function ($) {
    'use strict';

    $.fn.preventRepeatedSubmission = function (options) {
        const settings = $.extend({
            formSelector: 'form.prevent-repeated-submission',
            buttonSelector: 'input[type=submit],button[type=submit]'
        }, options);

        let submitting = false;

        function lock($button) {
            submitting = true;
            $button.prop('disabled', true);
        }

        function unlock($button) {
            submitting = false;
            $button.prop('disabled', false);
        }

        this.on('beforeSubmit', function (e) {
            if (submitting) {
                return false;
            }
            const $button = $(e.target).find(settings.buttonSelector);
            lock($button);

            $(window).on('popstate', function(){
                unlock($button); // ブラウザの戻るボタンで戻ってきたときロック状態のままになる Safari への対応
            });

            return true;
        });
    };

    // 戻るボタンでしっかり popstate させるおまじない（これが無いと popstate したりしなかったりする。。。）
    history.replaceState(null, document.getElementsByTagName('title')[0].innerHTML, null);

})(jQuery);
