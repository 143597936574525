import '../css/targeted-confirm-modal.css'
import TriggeredEvent = JQuery.TriggeredEvent

type ModalPlacement = 'top' | 'bottom' | 'left' | 'right'

interface ModalAdditionalClasses {
    text?: string
    okButton?: string
    cancelButton?: string
}

export default class TargetedConfirmModal {
    static readonly CLASS_NAME = {
        modal: 'targeted-confirm-modal',
        modalTitle: 'targeted-modal-title',
        modalTitleText: 'targeted-modal-title-text',
        modalBody: 'targeted-modal-body',
        modalBodyText: 'targeted-modal-body-text',
        modalFooter: 'targeted-modal-footer',
        okButton: 'ok-button',
        cancelButton: 'cancel-button',
        modalTarget: 'modal-target',
        modalOpen: 'open',
    }

    private static readonly DEFAULT_ADDITIONAL_CLASSES = {
        text: '',
        okButton: 'btn btn-sm btn-primary',
        cancelButton: 'btn btn-sm btn-default',
    }

    private static instance?: TargetedConfirmModal = null

    private modal: JQuery

    constructor() {
        this.init()
    }

    private init() {
        this.modal = $('body').find('#' + TargetedConfirmModal.CLASS_NAME.modal)
        if (this.modal.length === 0) {
            this.modal = this.renderModal()
        }
    }

    private renderModal(): JQuery {
        const modal = $('<div></div>')
            .attr('id', TargetedConfirmModal.CLASS_NAME.modal)
            .addClass(TargetedConfirmModal.CLASS_NAME.modal)
            .append(this.renderModalBody())
            .append(this.renderModalFooter())

        $('body').append(modal)
        return modal
    }

    private renderModalBody(): JQuery {
        const text = $('<p></p>')
            .addClass(TargetedConfirmModal.CLASS_NAME.modalBodyText)
        return $('<div></div>')
            .addClass(TargetedConfirmModal.CLASS_NAME.modalBody)
            .append(text)
    }

    private renderModalFooter(): JQuery {
        const okButton = $('<button type="button">はい</button>')
            .addClass(TargetedConfirmModal.CLASS_NAME.okButton)

        const cancelButton = $('<button type="button">いいえ</button>')
            .addClass(TargetedConfirmModal.CLASS_NAME.cancelButton)

        return $('<div></div>')
            .addClass(TargetedConfirmModal.CLASS_NAME.modalFooter)
            .append(okButton)
            .append(cancelButton)
    }

    public open(
        target: JQuery,
        message: string,
        placement: ModalPlacement[],
        ok: () => any,
        cancel?: () => any,
        additionalClasses?: ModalAdditionalClasses
    ): void {
        this.modal.find('.' + TargetedConfirmModal.CLASS_NAME.modalBodyText).text(message)
        this.modal.find('.' + TargetedConfirmModal.CLASS_NAME.okButton)
            .off()
            .one('click', () => {
                ok()
                this.close(target)
            })

        this.modal.find('.' + TargetedConfirmModal.CLASS_NAME.cancelButton)
            .off()
            .one('click', () => {
                if (cancel) {
                    cancel()
                }
                this.close(target)
            })

        this.applyAdditionalClasses(additionalClasses)

        $(document)
            .off('.targetedModal')
            .on('click.targetedModal', e => {
                this.closeModalByClickDisplay.apply(this, [e, target])
            })

        $(window)
            .off('.targetedModal')
            .on('resize.targetedModal', () => this.adjustModal(target, placement))

        target.addClass(TargetedConfirmModal.CLASS_NAME.modalOpen)
        this.modal.addClass(TargetedConfirmModal.CLASS_NAME.modalOpen)
        this.adjustModal(target, placement)
    }

    private applyAdditionalClasses(additionalClasses?: ModalAdditionalClasses): void {
        additionalClasses = {...TargetedConfirmModal.DEFAULT_ADDITIONAL_CLASSES, ...additionalClasses}

        this.modal
            .find('.' + TargetedConfirmModal.CLASS_NAME.modalBodyText)
            .attr('class', '')
            .addClass(TargetedConfirmModal.CLASS_NAME.modalBodyText)
            .addClass(additionalClasses?.text)

        this.modal
            .find('.' + TargetedConfirmModal.CLASS_NAME.okButton)
            .attr('class', '')
            .addClass(TargetedConfirmModal.CLASS_NAME.okButton)
            .addClass(additionalClasses?.okButton)

        this.modal
            .find('.' + TargetedConfirmModal.CLASS_NAME.cancelButton)
            .attr('class', '')
            .addClass(TargetedConfirmModal.CLASS_NAME.cancelButton)
            .addClass(additionalClasses?.cancelButton)
    }

    private close(target: JQuery): void {
        this.modal.removeClass(TargetedConfirmModal.CLASS_NAME.modalOpen)
        target.removeClass(TargetedConfirmModal.CLASS_NAME.modalOpen)
    }

    private closeModalByClickDisplay(e: TriggeredEvent, target: JQuery): void {
        if ($(e.target).closest('.' + TargetedConfirmModal.CLASS_NAME.modal).length > 0) {
            return
        }

        if ($(e.target).closest('.' + TargetedConfirmModal.CLASS_NAME.modalTarget).length > 0) {
            return
        }

        this.close(target)
    }

    private adjustModal(target: JQuery, placement: ModalPlacement[]): void {
        this.modal
            .css({
                top: '',
                left: '',
            })
            .removeClass('top bottom left right')

        const offset = target.offset()
        const tp = {}
        const top = offset.top - this.modal.outerHeight() - 2
        const bottom = offset.top + target.outerHeight() + 2
        let posCss: string

        if (placement.includes('top') && top > 0) {
            tp['top'] = top
            posCss = 'top'
        } else {
            tp['top'] = bottom
            posCss = 'bottom'
        }

        if (placement.includes('left')) {
            let left = offset.left
            const modalEnd = left + this.modal.outerWidth()
            if (modalEnd > $(document).outerWidth()) {
                // モーダルの右がはみ出していたら
                left -= modalEnd - $(document).outerWidth()
            }
            tp['left'] = left
        } else {
            // モーダルの左がはみ出すなら表示位置を画面の左端に合わせる
            tp['left'] = Math.max(offset.left + target.outerWidth() - this.modal.outerWidth(), 0)
        }

        this.modal
            .css(tp)
            .addClass(posCss)
    }

    static getInstance(): TargetedConfirmModal {
        if (TargetedConfirmModal.instance === null) {
            TargetedConfirmModal.instance = new TargetedConfirmModal()
        }

        return TargetedConfirmModal.instance
    }

    static confirm(
        target: JQuery,
        message: string,
        placement: ModalPlacement[],
        ok: () => any,
        cancel?: () => any,
        additionalClasses?: ModalAdditionalClasses
    ): void {
        this.getInstance().open(target, message, placement, ok, cancel, additionalClasses)
    }
}
