export default {
    'solid-0': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-0" */'./fa-index-free-solid-0'),
    'solid-1': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-1" */'./fa-index-free-solid-1'),
    'solid-2': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-2" */'./fa-index-free-solid-2'),
    'solid-3': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-3" */'./fa-index-free-solid-3'),
    'solid-4': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-4" */'./fa-index-free-solid-4'),
    'solid-5': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-5" */'./fa-index-free-solid-5'),
    'solid-6': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-6" */'./fa-index-free-solid-6'),
    'solid-7': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-7" */'./fa-index-free-solid-7'),
    'solid-8': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-8" */'./fa-index-free-solid-8'),
    'solid-9': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-9" */'./fa-index-free-solid-9'),
    'solid-c': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-c" */'./fa-index-free-solid-c'),
    'solid-e': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-e" */'./fa-index-free-solid-e'),
    'solid-a': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-a" */'./fa-index-free-solid-a'),
    'solid-f': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-f" */'./fa-index-free-solid-f'),
    'solid-d': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-d" */'./fa-index-free-solid-d'),
    'solid-b': ()=>import(/* webpackChunkName: "fontawesome/fa-index-free-solid-b" */'./fa-index-free-solid-b'),
}