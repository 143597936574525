import { library,dom } from '@fortawesome/fontawesome-svg-core'

import {
    faSquareMinus as faSquareMinusFreeRegular,
    faSquarePlus as faSquarePlusFreeRegular,
    faEnvelope as faEnvelopeFreeRegular,
    faCircleCheck as faCircleCheckFreeRegular,
    faSquareCaretDown as faSquareCaretDownFreeRegular,
    faImage as faImageFreeRegular,
} from '@fortawesome/free-regular-svg-icons'

import {
    faSpinner as faSpinnerFreeSolid,
    faLock as faLockFreeSolid,
    faBullhorn as faBullhornFreeSolid,
    faBan as faBanFreeSolid,
    faRss as faRssFreeSolid,
    faTriangleExclamation as faTriangleExclamationFreeSolid,
    faInbox as faInboxFreeSolid,
    faGear as faGearFreeSolid,
    faCircleCheck as faCircleCheckFreeSolid,
    faClipboard as faClipboardFreeSolid,
    faCircleInfo as faCircleInfoFreeSolid,
    faTrash as faTrashFreeSolid,
    faArrowRight as faArrowRightFreeSolid,
    faReply as faReplyFreeSolid,
    faMaximize as faMaximizeFreeSolid,
    faXmark as faXmarkFreeSolid,
    faPaperclip as faPaperclipFreeSolid,
    faQuoteLeft as faQuoteLeftFreeSolid,
    faFont as faFontFreeSolid,
    faBold as faBoldFreeSolid,
    faItalic as faItalicFreeSolid,
    faFilm as faFilmFreeSolid,
    faTextHeight as faTextHeightFreeSolid,
    faHeading as faHeadingFreeSolid,
    faLink as faLinkFreeSolid,
    faListOl as faListOlFreeSolid,
    faStrikethrough as faStrikethroughFreeSolid,
    faTable as faTableFreeSolid,
    faListUl as faListUlFreeSolid,
    faUnderline as faUnderlineFreeSolid,
    faUpload as faUploadFreeSolid,
    faArrowsRotate as faArrowsRotateFreeSolid,
} from "@fortawesome/free-solid-svg-icons";

import {
    faFaceSmilePlus as faFaceSmilePlusProRegular,
    faMessageSlash as faMessageSlashProRegular,
} from "@fortawesome/pro-regular-svg-icons";
import {requireIconsFor} from "./fonts-dynamic";

library.add(
    faSquarePlusFreeRegular,
    faSquareMinusFreeRegular,
    faEnvelopeFreeRegular,
    faCircleCheckFreeRegular,
    faSquareCaretDownFreeRegular,
    faImageFreeRegular,

    faSpinnerFreeSolid,
    faLockFreeSolid,
    faBullhornFreeSolid,
    faBanFreeSolid,
    faRssFreeSolid,
    faTriangleExclamationFreeSolid,
    faInboxFreeSolid,
    faGearFreeSolid,
    faCircleCheckFreeSolid,
    faClipboardFreeSolid,
    faCircleInfoFreeSolid,
    faTrashFreeSolid,
    faArrowRightFreeSolid,
    faReplyFreeSolid,
    faMaximizeFreeSolid,
    faXmarkFreeSolid,
    faPaperclipFreeSolid,
    faQuoteLeftFreeSolid,
    faFontFreeSolid,
    faBoldFreeSolid,
    faItalicFreeSolid,
    faFilmFreeSolid,
    faTextHeightFreeSolid,
    faHeadingFreeSolid,
    faLinkFreeSolid,
    faListOlFreeSolid,
    faStrikethroughFreeSolid,
    faTableFreeSolid,
    faListUlFreeSolid,
    faUnderlineFreeSolid,
    faUploadFreeSolid,
    faArrowsRotateFreeSolid,

    faFaceSmilePlusProRegular,
    faMessageSlashProRegular,
)

jQuery(function () {
    const dynamicIconAreas = jQuery('.ad-hoc-hint');
    requireIconsFor(dynamicIconAreas).finally(() => {
        dom.watch();
    });
});
