/**
 * 埋込コンテンツの遅延ロードに付随する調整処理
 */

(function($) {

    function _heighten($_container) {
        $_container.find('div[data-lazy-widget]').each(function(){
            const _this = $(this);
            let contentLabel = '外部サイトのコンテンツ';
            if (_this.data('resource-url')) {
                const match = String(_this.data('resource-url')).match(/^(https?:)\/\/(([^:\/?#]*)(?::(\d+))?)(\/?[^?#]*)(\?[^#]*)(#.*)$/);
                if (match) {
                    contentLabel = match[2]; // ホスト名
                }
            }
            _this.css('color','gray')
                .css('padding','20px')
                .css('border', '1px solid #e0e0e0')
                .append('<i class="fa-solid fa-spinner fa-spin"></i>' + contentLabel + ' を読み込み中... ');
            const h = _this.data('lazy-widget-height');
            if (h) {
                _this.css('height', h);
            }
        });
    }

    window.lazyload_fix = {
        init: function() {
            _heighten($(document.body));

            $('.drawer-discussion-aside').on('scroll', function(){
                $(document).trigger('scroll');
            });
        },
        prepare: function($container) {
            // comment-form-position-fix.js と重複するかもしれんけど
            $container.css('min-height', $container.height()); // 高さが縮まないようにする
        },
        done: function($container) {
            _heighten($container);
            $container.find('div[data-lazy-widget]').lazyLoadXT();

            if (!!window.twttr) {
                setTimeout(function () {
                    // widgets.js がクラッシュするので念のためスタックフレームを分けておく
                    window.twttr.widgets.load().catch(function () {
                        // 初回リロード時(2回目の load 時)だけなぜか失敗するバグがある
                        // 再実行(3回目の load)するとなぜか成功する
                        window.twttr.widgets.load().catch(function () { });
                    });
                }, 1);
            }
            if (!!window.FB) {
                window.FB.XFBML.parse();
            }
            if (!!window.instgrm) {
                window.instgrm.Embeds.process();
            }
            if (!!window.FlickrEmbedr) {
                // Flickr のスクリプトのバグ回避。
                // フォーラムにて報告・修正依頼済み。
                // https://www.flickr.com/groups/api/discuss/72157672112910074/
                // TODO バグが修正されれば不要なので取り除く。
                window.FlickrEmbedr.process("inline");
            }
            if (!!window.imgurEmbed) {
                // 万一無限ループしたら嫌なので一応回数リミットつけとく
                for (let i=0; $('blockquote.imgur-embed-pub').length > 0 && i<100; i++) {
                    window.imgurEmbed.createIframe();
                }
            }

            const pixivProc = function () {
                if (!!window.__pixiv__) {
                    window.__pixiv__.embed();
                }
            };
            if ($container.find('.pixiv-embed').length > 0 && !window.__pixiv__) {
                $('<script>').appendTo($('body')).on('load', pixivProc).attr('src', '//source.pixiv.net/source/embed.js');
            } else {
                pixivProc();
            }

            if (!!window.iFrameResize) {
                $container.find('iframe.external-resource').each(function() {
                    window.iFrameResize({heightCalculationMethod: 'bodyOffset', checkOrigin: false}, this);
                });
            }

            // comment-form-position-fix.js と重複するかもしれんけど
            (function waitForWidgetsRendered() {
                if( $container.find('blockquote.twitter-tweet').length === 0 &&
                    $container.find('.fb-post[fb-xfbml-state!="rendered"]').length === 0 &&
                    $container.find('.pixiv-embed[data-done!="1"]').length === 0
                ) {
                    $container.css('min-height', ''); // 元に戻す
                } else {
                    setTimeout(waitForWidgetsRendered, 1000);
                }
            })();
        }
    };

    $(function () {
        window.lazyload_fix.init();
    });

})(jQuery);
