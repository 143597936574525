import './css/common.css';
import './css/comment.css';
import './css/scroll-to-top-bottom.css';
import './css/media-modal.css';
import './css/ad-block.css';

import './js/app';
import './js/comment-form';
import './js/comment-refresh';
import './js/content-operations';
import './js/page-view-counter';
import './js/scroll-to-top-bottom';
import './js/lazy-load-fix';
import './js/hide-comments';
import './js/media-modal';
import './js/close-hint-area';
import './js/paragraph-collapse';
import './js/subtree-loader';
import './js/jquery.comment-action-menu';

require('../../common/fonts');
