(function($){
    $.fn.scrollToTopBottom = function(options) {
        const $container = $(this);
        options = $.extend({
            top: null,
            bottom: null
        }, options);

        function topElement() {
            const e = $container.find(options.top);
            return e.length > 0 ? e : $container.find(':first');
        }

        function bottomElement() {
            const e = $container.find(options.bottom);
            return e.length > 0 ? e : $container.find(':last');
        }

        function animateScrollTo(pos) {
            //$container.animate({scrollTop: pos}, 400, 'swing', function() {
                $container.scrollTop(pos);
                updateButtonsVisibility();
            //});
        }

        const toTop = $('<button>').addClass('scroll-to-top-bottom scroll-to-top-bottom-top').append(
            '<span class="glyphicon glyphicon-arrow-up"></span>'
        ).on('click', function () {
            animateScrollTo(topElement().offset().top);
        }).appendTo(document.body);

        const toBottom = $('<button>').addClass('scroll-to-top-bottom scroll-to-top-bottom-bottom').append(
            '<span class="glyphicon glyphicon-arrow-down"></span>'
        ).on('click', function () {
            animateScrollTo(bottomElement().offset().top);
        }).appendTo(document.body);

        function updateButtonsVisibility() {
            const sp = $(window).scrollTop();
            if (sp > topElement().offset().top) {
                toTop.fadeIn();
            } else {
                toTop.hide();
            }
            if (sp + $(window).height() < bottomElement().offset().top) {
                toBottom.fadeIn();
            } else {
                toBottom.hide();
            }
        }

        let scrolling = null;
        $(window).on('scroll', function() {
            if (scrolling) {
                clearTimeout(scrolling);
            }
            scrolling = setTimeout(function() {
                // console.log('to');
                updateButtonsVisibility();
                scrolling = null;
            }, 500);
        });

        updateButtonsVisibility();
    };
})(jQuery);