(function($) {

    const DELAY_MIN = 10;
    const DELAY_MAX = 640; // 10 * 4^3
    const DELAY_DOWN_RATIO = 4;
    const AUTO_POLLING_LIMIT = 8; // times だいたい 1h

    // 概算
    // delay: 10s, 40s, 160s, 640s, 640s, 640s, 640s, 640s
    // elapsed: 10s, 50s, 210s, 850s, 1490s, 2130s, 2770s, 3410s

    $.fn.commentRefresh = function(options) {
        options = $.extend({
            url: null,
            itemsContainer: null,
            maxNumberFetcher: function($container) {
                return parseInt($container.find('.comments').data('max-comment-number'));
            },
            lastTimestampFetcher: function($container) {
                return parseInt($container.find('.comments').data('last-timestamp'));
            },
            pageSize: 0
        }, options);

        const $refreshButton = $(this);
        const $itemsContainer = $(options.itemsContainer);
        const $progressCount = $('<span>').addClass('new-comments badge').text('').css({
            display: 'none'
        }).appendTo($refreshButton);

        const maxNumberFetcher = options.maxNumberFetcher;
        const lastTimestampFetcher = options.lastTimestampFetcher;
        let lastNumber = maxNumberFetcher($itemsContainer);
        let lastTimestamp = lastTimestampFetcher($itemsContainer);
        let delay = DELAY_MIN;
        let cycle = 0;
        let originalTitle = null;
        let pendingAjax = false;

        let timeout = null;

        function start() {
            if (timeout) {
                return;
            }
            pendingAjax = false;
            timeout = setTimeout(function() {
                poll(options.url, function() {
                    timeout = null;
                }, function() {
                    delay = Math.min(delay * DELAY_DOWN_RATIO, DELAY_MAX);
                    if (++cycle < AUTO_POLLING_LIMIT) {
                        start();
                    }
                });
            }, delay * 1000);
        }
        function stop() {
            if (timeout) {
                clearTimeout(timeout);
                pendingAjax = true;
                timeout = null;
            }
        }
        function poll(url, beforePoll, afterPoll) {
            beforePoll();
            $.ajax(url, {
                //cache: false
            }).done(function(data) {
                if (pendingAjax) { return; }

                if (data.hasOwnProperty('commented_at')) {
                    if (data.commented_at > lastTimestamp) {
                        maxNumberUpdated(data.comments);
                        lastTimestamp = data.commented_at;
                    }
                } else {
                    maxNumberUpdated(data.comments); // API後方互換
                }

            }).fail(function() {

            }).always(function() {
                if (pendingAjax) { return; }
                afterPoll();
            });
        }
        function injectNewArrivalOr(fallback) {
            if (!$refreshButton.is('.should-inject-new-arrival')) {
                fallback();
                return;
            }

            const initialScrollTop = $(window).scrollTop();
            const initialOffsetTop = $refreshButton.offset().top;

            // 以上クリアなら
            const maxNumber = lastNumber;

            $refreshButton.addClass('disabled');
            const $spinner = $refreshButton.find('.glyphicon,.fa').addClass('fa-spin');

            $itemsContainer.trigger('pjax:start');

            $.ajax({
                url: window.location.href,
                dataType: 'html',
                data: {_pjax: containerSelector}
            }).always(function(){
                $refreshButton.removeClass('disabled');
                $spinner.removeClass('fa-spin');
            }).fail(function() {
                fallback();
            }).done(function(data){
                const fetchedContainer = $($.parseHTML(data)).find(containerSelector);
                let newItems = $();
                fetchedContainer.find(listSelector).children('[data-key]').each(function(){
                    const key = Number($(this).data('key'));
                    if (key > maxNumber) {
                        newItems = newItems.add(this);
                    }
                });

                // 非表示状態で DOM 追加
                const listElement = $(containerSelector).find(listSelector); // 理由不明だけど、これでいけそう
                listElement.append(newItems.addClass('hidden'));

                // 表示件数を超過したコメントは先頭から取り除く
                let oldItems = $();
                const items = listElement.children('[data-key]');
                if (items.length > options.pageSize) {
                    oldItems = oldItems.add(items.slice(0, items.length - options.pageSize));
                }

                // レンダリング更新系プラグインを適用
                newItems.find('.emoji-text').emojify();
                newItems.find('.paragraph-collapse').paragraphCollapse();
                if (!!$.fn.expandCommentRef) {
                    newItems.find('.comment .header .comment-parent-link').expandCommentRef({headerAnchor:true});
                    newItems.find('.comment .content .body .comment-parent-link').expandCommentRef();
                }
                Prism.highlightAll();
                $itemsContainer.trigger('pjax:success');

                // pjax:success から10ミリ秒遅れで表示更新するやつ（lazyload_fix / hide_comments）対策。
                setTimeout(function(){
                    newItems.removeClass('hidden').addClass('last-posted-comment-effect');
                    oldItems.remove();

                    // スクロール位置を調整
                    const deltaY = $refreshButton.offset().top - initialOffsetTop;
                    $(window).scrollTop(initialScrollTop + deltaY);
                }, 20);

                // data- や var の値を更新
                const fetchedComments = fetchedContainer.find('.comments');
                $itemsContainer.find('.comments')
                    .data('max-comment-number', fetchedComments.data('max-comment-number'))
                    .data('last-timestamp', fetchedComments.data('last-timestamp'));
                updateCurrentNumber();

            });
        }
        function maxNumberUpdated(maxNumber) {
            if (maxNumber !== lastNumber) {
                injectNewArrivalOr(function() {
                    const c = maxNumber - maxNumberFetcher($itemsContainer);
                    if (c > 0) {
                        $progressCount.text(c).css('display', '');
                        changePageTitle(c);
                    }
                    lastNumber = maxNumber;
                    delay = DELAY_MIN;
                });
            }
        }
        function changePageTitle(n) {
            const $title = $('title');
            if (!originalTitle) {
                originalTitle = $title.text();
            }
            $title.text('(' + n + ') ' + originalTitle);
        }
        function resetPageTitle() {
            if (originalTitle) {
                $('title').text(originalTitle);
                originalTitle = null;
            }
        }

        function updateCurrentNumber() {
            $progressCount.text('').css('display', 'none');
            lastNumber = maxNumberFetcher($itemsContainer);
            lastTimestamp = lastTimestampFetcher($itemsContainer);
            delay = DELAY_MIN;
            cycle = 0;
            originalTitle = null;
        }

        $itemsContainer.on("pjax:start", function() {
            stop();
            resetPageTitle();
        }).on("pjax:success", function() {
            updateCurrentNumber();
            //}).on("pjax:error", function(event, xhr, textStatus, errorThrown, options) {
        }).on("pjax:end", function(/*event, xhr, options*/) {
            start();
        });

        start();
        //poll(options.url, stop, start);


        // 条件に従って、自動リロードをオン・オフする。
        //
        // 条件０：想定通りの DOM 要素があること
        const containerSelector = '#' + $itemsContainer.attr('id');
        const listSelector = '.list-view';
        const listElement = $(containerSelector).find(listSelector);
        if (listElement.length === 1) {

            function shouldInjectNewArrival() {
                // 条件１：タイムラインモードであること
                if ($itemsContainer.find('.comments').is('.view-mode-timeline') === false) {
                    return false;
                }

                // 条件２：リフレッシュボタン下のテキストエリアが見える位置までスクロールしていること
                const $textarea = $refreshButton.closest('.comment-form-container').find('textarea');
                if ($textarea.length !== 1) {
                    return false;
                }
                const y = $textarea.offset().top;
                const minY = $(window).scrollTop();
                const maxY = minY + $(window).height();
                if (y < minY || y > maxY) {
                    return false;
                }

                // 条件３：バッヂがついていないこと
                // noinspection RedundantIfStatementJS
                if ($refreshButton.find('.badge').is(':visible')) {
                    return false;
                }

                return true;
            }

            function updateInjectFeature() {
                const onClasses = 'btn-primary button-flash-effect should-inject-new-arrival';
                const offClasses = 'btn-default';
                if ($refreshButton.is('.btn-default')) {
                    if (shouldInjectNewArrival()) {
                        $refreshButton.addClass(onClasses).removeClass(offClasses);
                    }
                } else {
                    if (!shouldInjectNewArrival()) {
                        $refreshButton.addClass(offClasses).removeClass(onClasses);
                    }
                }
            }

            $(window).on('scroll', function(){
                updateInjectFeature();
            });
            $refreshButton.on('commentForm:changeState', function(){
                updateInjectFeature();
            });
            $refreshButton.on('commentForm:post', function(){
                stop();
                injectNewArrivalOr(function(){
                    $refreshButton.trigger('click');
                });
                start();
            });
        }

    };
})(jQuery);
