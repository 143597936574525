(function($) {

    $.fn.paginationSupportReadNext = function (options) {
        options = $.extend({
            itemsContainer: null,
            firstElementOfNext: null
        }, options);

        const $container = $(this);
        const $button = $container.find('a');
        const $itemsContainer = options.itemsContainer;

        function isFirstPage() {
            const $firstPaginationButton = $itemsContainer.find('.pagination .first');
            return $firstPaginationButton.length === 0 || $firstPaginationButton.hasClass('disabled');
        }

        function getPrevPaginationButton() {
            return $itemsContainer.find('.pagination .prev a');
        }

        function updateVisibility() {
            if (isFirstPage()) {
                $container.hide();
                $button.attr('href', '#');
            } else {
                $container.show();
                $button.attr('href', getPrevPaginationButton().attr('href'));
            }
        }

        $itemsContainer.on("pjax:end", function() {
            updateVisibility();
        });

        $button.on('click', function() {
            getPrevPaginationButton().trigger('click');
            $itemsContainer.one("pjax:success", function(){
                $('html, body').scrollTop(
                    $itemsContainer.find(options.firstElementOfNext).offset().top
                );

                // dirty hack to fix affix after pjax
                $('.discussion-aside .info').affix('checkPosition');
            });
            return false;
        });

        updateVisibility();
    }
})(jQuery);
